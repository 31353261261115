<template>
  <div :class="['order', { 'order--loading': isOrderLoading }]">
    <div class="product-wrapper">
      <ProductCard
        v-if="purchaseType == PurchaseType.PRODUCT"
        :loading="isOrderLoading"
        :product="purchase(this.uuid)?.product"
      />
      <SubscriptionCard
        v-else
        :subscription="purchase(this.uuid)?.subscription"
      />
    </div>
    <div class="order-info">
      <Chip v-if="isOrderLoading" :color="'green'" small quadrat stroke loading
        >Загрузка</Chip
      >
      <div v-else class="order-status">
        <Chip
          v-if="
            purchase(this.uuid)?.status == 'PAID' ||
            purchase(this.uuid)?.status == 'USED'
          "
          :color="'green'"
          small
          quadrat
          stroke
          >Оплачено</Chip
        >
        <Chip
          v-if="purchase(this.uuid)?.status == 'PENDING'"
          :color="'yellow'"
          small
          quadrat
          stroke
          >В обработке</Chip
        >
        <Chip
          v-if="purchase(this.uuid)?.status == 'REJECTED'"
          :color="'red'"
          small
          quadrat
          stroke
          >Платёж отклонён</Chip
        >
        <Chip
          v-if="purchase(this.uuid)?.status == 'REFUNDED'"
          :color="'gray'"
          small
          quadrat
          stroke
          >Возвращен</Chip
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "./payments/ProductCard.vue";
import SubscriptionCard from './payments/SubscriptionCard.vue'
// import SquareButton from '../components/SquareButton.vue';
import { PurchaseType } from "../utilities/purchasesType";
import { PaymentMethods } from "../utilities/PaymentMethods";
import LoadingLogo from "./LoadingLogo.vue";
import Chip from "./Chip.vue";

export default {
  props: {
    uuid: {
      type: String,
      default: "",
    },
    purchaseType:{
      type:String
    } 
  },
  components: {
    ProductCard,
    SubscriptionCard,
    LoadingLogo,
    Chip,
  },
  data() {
    return {
      PurchaseType,
      PaymentMethods,
      isOrderLoading: true,
      socketPurchase: null,
      order: null,
      timerStart: null,
      reconnectTimeout: 15 * 60 * 1000, // 15 minutes (ms)

      statuses: {
        PAID: {
          heading: "Congratulations on your purchase!",
          label: "Order successfully paid.",
          icon: "success_filled",
        },
        ISSUED: {
          heading: "Congratulations on your purchase!",
          label: "Order successfully issued.",
          icon: "success_filled",
        },
        REJECTED: {
          heading: "Unfortunately, the order is rejected.",
          label: "Order rejected.",
          icon: "failure_filled",
        },
        REFUNDED: {
          heading: "The order was refunded.",
          label: "Order was refounded",
          icon: "failure_filled",
        },
        USED: {
          heading: "The product has already been used",
          label: "Product was used",
          icon: "pending_filled",
        },
        PENDING: {
          heading: "Wait a minute! Order is being processed.",
          label: "Order still in processing",
          icon: "pending_filled",
        },
      },
    };
  },
  async mounted() {
    this.isOrderLoading = true
    try {
      if (!this.purchase(this.uuid)) {
        await this.fetchPurchases();
      }

      console.log(this.purchase(this.uuid));
      await this.observeUserPurchases([this.purchase(this.uuid)]);
    } catch (e) {
    } finally {
      this.isOrderLoading = false
    }
  },
  beforeUnmount() {},
  methods: {
    ...mapActions(["observeUserPurchases", "fetchPurchases"]),

    navigateToPurchases() {
      this.$router.push({ name: "Purchases" });
    },

    getFormattedDate(dateString) {
      const date = new Date(dateString);
      const day = date.toLocaleDateString("ru-RU");
      const time = date.toLocaleTimeString("ru-RU");
      return `${day} ${time}`;
    },

    getPaymentMethodName(paymentTypeKey) {
      const paymentType = Object.values(PaymentMethods).find(
      
        (p) => {alert(p.paymentType);p.paymentType == paymentTypeKey}
      );
      return paymentType ? paymentType.name : paymentTypeKey;
    },
  },
  computed: {
    ...mapGetters(["purchase"]),

    userPurchase() {
      return this.purchase(this.uuid);
    },

    currentStatus() {
      const { status } = this.purchase(this.uuid);
      const currentStatus = this.statuses[status];
      const defaultStatus = this.statuses.PENDING;
      return currentStatus ?? defaultStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.order {
  width: 100%;
  display: flex;
  align-items: center;

  .product-wrapper {
    width: 100%;
  }

  .order-info {
    margin-left: 20px;
  }
}

.order--loading {
}
</style>
