<template>
  <button class="subscription" @click="goToPayment" >
    <div class="icon">
      <img
        :src='require("@/assets/landing/icons/SERVICE.png")'
        alt=""
        class="colored-image"
      />
    </div>
    <div class="info">
      <h3 class="name">PREMIUM SUBSCRIPTION</h3>
      <div class="expires">
        <p class="subtitle">expires in 90 days</p>
      </div>
      <div class="go-to-payments">
        <p class="subtitle">go to payments</p>
        <SvgIcon :path="icons.arrow" type="mdi" />
      </div>
    </div>
  </button>
</template>
<script>
import { mdiChevronDoubleRight } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
export default {
  props:{
    userSubscription:{
      type: Object
    }
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      icons: {
        arrow: mdiChevronDoubleRight,
      },
    };
  },
  methods:{
    goToPayment(){
      this.$router.push({name:'Purchases'})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.subscription {
  display: flex;
  align-items: center;
  padding: 7px;
  border: 2px solid $red;
  background: transparent;
  border-radius: 12px;
  width: 100%;
  color: $red;
  cursor: pointer;
  transition: .5s;

  &:enabled:hover {
    // color: $orange;
    border-color: $orange;
    transform: translateY(-3px);

    background: rgba(233, 163, 119, 0.1);

    .icon {
      transform: translateY(-2px);

      img {
        filter: brightness(1) sepia(0.9) hue-rotate(-35deg) saturate(5);
      }
    }

    .expires {
      display: none;
    }

    .go-to-payments {
      height: 16px;
      display: flex;
      align-items: center;
    }
  }

  &:enabled:active {
    transition: .2s;
    transform: translateY(3px);
  }

  .icon {
    transition: 0.5s;
    height: 60px;
    img {
      transform: translateY(-10px);
      width: 60px;
      // height: 60px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    position: relative;

    .name {
      padding: 0;
      margin: 0;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .subtitle {
      padding: 0;
      margin: 0;
      font-size: 14px;
      text-align: start;
    }
  }
}

.go-to-payments {
  display: none;
}
</style>