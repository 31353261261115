<template>
  <div
    :class="['input', $attrs.class, v$.modelValue.$errors?.length && 'input--error']"
    @click="lockClickHandler"
  >
    <div :class="['input__header', offer ? 'input__header--offered' : '']">
      <label :for="$.uid" class="input__label" v-if="label">
        {{ label }}
      </label>

      <button
        v-if="offer"
        class="input__offer"
        @click="offerClickHandler"
      >
        {{ offer }}
      </button>
      <div
        v-else-if="locked"
        class="input__lock"
        :title="lockReason"
      >
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#lock'}" />
        </svg>
      </div>
    </div>
    <input class="input__input"
      ref="internalInput" 
      :disabled="locked"
      v-bind="{...$attrs, 'v-model': null}"
      :id="$.uid"
      :placeholder="placeholder"
      :value="modelValue"
      @input="(e) => inputHandler(e.target.value)"
      @blur="v$.modelValue.$touch"
    >
    <div class="input__error" v-if="showErrors && v$.modelValue.$errors?.length">
      {{ v$.modelValue.$errors[0]?.$message }}
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';

export default {
  emits: ['unlock', 'offerClick'],
  inheritAttrs: false,
  props: {
    label: String,
    validations: Object,
    modelValue: {
      type: String || Number,
      default: '',
    },
    placeholder: String,
    showErrors: {
      type: Boolean,
      default: true,
    },
    locked: Boolean,
    disabled: Boolean,
    lockReason: {
      type: String,
      default: '',
    },
    offer: {
      type: String,
      default: '',
    },
  },
  mounted(){
    this.$emit('set-input-ref', this.$refs.internalInput);
  },
  data () {
    return {
      v$: useValidate(),
    };
  },
  validations () {
    return {
      modelValue: this.validations ?? {},
    };
  },
  methods: {
    inputHandler (value) {
      this.$emit('update:modelValue', value);
    },
    lockClickHandler () {
      if (this.locked) {
        this.$emit('unlock');
      }
    },
    offerClickHandler () {
      this.$emit('offerClick');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.input {
  display: flex;
  flex-direction: column;

  width: 100%;

  &--error {
    .input__input {
      border-color: $red;
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 5px;
    margin-left: 12px;

    &--offered {
      align-items: center;
      justify-content: space-between;
    }
  }

  &__offer {
    padding: 0;
    background: none;
    border: none;
    color: $orange;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
  }

  &__label {
    font-size: 1.125rem;
    white-space: nowrap;
  }

  &__lock {
    cursor: pointer;
    svg {
      width: 11px;
      height: 11px;
      fill: $red;
      stroke: $red;
    }
  }

  &__input {
    box-sizing: border-box;
    height: 40px;
    padding-top: 2px;
    padding-left: 16px;
    width: 100%;

    transition: .2s;

    border: 1px solid #D1D1D1;
    border-radius: 11px;

    font-size: 1rem;

    &::placeholder{
      color: #CACCCF;
    }

    &:disabled {
      background: $light-gray;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:focus {
      transition: .3s;

      outline: none;
      background: #f9f9f9;
    }

    &[type=date], &[type=time] {
      padding-right: 15px;
      &::-webkit-calendar-picker-indicator {
        filter: invert(.5);
      }
    }
  }

  &__error {
    padding-left: 12px;
    font-size: 14px;
    color: $red;
    left: 12px;
    bottom: -10px;
    padding-top: 4px;
  }
}

.disabled{
  &:disabled{
      background: $white;
      cursor: pointer;
      pointer-events:auto;
  }
}
</style>
