<template>
  <div :class="['order-page', { 'order-page--loadung': isOrderLoading }]">
    <div class="title-wrapper">
      <h1 class="title">Платёж</h1>
      <div class="order-date">
        {{ orderDate }}
      </div>
    </div>
    <div class="orders-wrapper">
      <div v-for="uuid in uuids" :key="uuid" class="order-wrapper">
        <Order :uuid="uuid" :purchaseType="purchaseType" />
      </div>
    </div>
    <div class="order-info">
      <!-- требует доработки т.к неоплаченные заказы не привязываются к релизу -->
      <div class="release" v-if="isProductType && false">
        <div class="cover">
          <img :src="release.coverCdnLink" alt="" />
        </div>
        <div class="release-info">
          <div class="title">{{ release.title }}</div>
          <div class="artists">
            <span v-for="(artistName, index) in artistsNames" :key="artistName">
              {{ index < artistsNames.length ? artistName + ", " : artistName }}
            </span>
          </div>
        </div>

        <div class="go-to-release-btn">
          <Button small transparent @click="goToReleasePreview">
            перейти к релизу
            <template v-slot:post-icon>
              <SvgIcon type="mdi" :path="icons.arrow" />
            </template>
          </Button>
        </div>
      </div>
      <transition name="fade">
        <PaymentTypeIcon
          v-if="method && !isOrderLoading"
          :method="method"
          :selected="false"
        />
      </transition>
    </div>
    <transition name="fade">
      <InfoSubtitle>
        Услуги, связанные с текущим релизом, могут быть использованы повторно
        для другого релиза в случае отказа в его публикации. Если у вас остались
        вопросы, пожалуйста, обратитесь в поддержку:
        <a href="mailto:mail@mylabel.cc">mail@mylabel.cc</a>
      </InfoSubtitle>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PaymentMethods } from "@/utilities/PaymentMethods";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiChevronDoubleRight,
  mdiInformationVariantCircleOutline,
} from "@mdi/js";
import PaymentTypeIcon from "../components/payments/PaymentTypeIcon.vue";
import Order from "../components/Order.vue";
import Button from "../components/RedButton.vue";
import { PurchaseType } from "../utilities/purchasesType";
import InfoSubtitle from "../components/payments/InfoSubtitle.vue";

export default {
  components: { Order, PaymentTypeIcon, Button, SvgIcon, InfoSubtitle },
  data() {
    return {
      icons: {
        arrow: mdiChevronDoubleRight,
        info: mdiInformationVariantCircleOutline,
      },
      release: {
        coverCdnLink: require("@/assets/placeholder.png"),
      },
      PaymentMethods,
      PurchaseType,
      showReleaseInfo: true,
      artistsNames: [],
      isOrderLoading: true,
      purchaseType: null,
    };
  },
  async mounted() {
    this.isOrderLoading = true;
    try {
      this.purchaseType = this.$route.query.orderType;

      if (this.purchaseType == PurchaseType.PRODUCT) {
        this.release = (await this.fetchUserProductRelease(this.uuids[0])).data;

        const coverCdnLink = (
          await this.getFileCdnLink(this.release.cover_uuid)
        ).data;
        this.release.coverCdnLink = coverCdnLink;

        const primaryArtists = this.release.artists.filter(
          (artist) => artist.role == "PRIMARY_ARTIST"
        );

        let artists = primaryArtists;
        if (!primaryArtists.length) {
          artists = this.release.artists;
        }

        this.artistsNames = await Promise.all(
          artists.map(async (releaseArtist) => {
            const artist = (await this.fetchArtist(releaseArtist.artist_uuid))
              .data;
            return artist.name;
          })
        );
      }
    } catch (e) {
    } finally {
      this.isOrderLoading = false;
    }
  },
  methods: {
    ...mapActions(["fetchUserProductRelease", "getFileCdnLink", "fetchArtist"]),

    goToReleasePreview() {
      this.$router.push({
        name: "Release",
        params: { uuid: this.release.uuid },
      });
    },
  },
  computed: {
    ...mapGetters(["release", "purchase"]),

    isProductType() {
      return this.purchaseType == PurchaseType.PRODUCT;
    },

    uuids() {
      const { base64 } = this.$route.params;
      const uuidsArr = Buffer.from(base64, "base64")
        .toString("utf-8")
        .split(",");
      return uuidsArr;
    },

    method() {
      return Object.values(this.PaymentMethods).find(
        (method) =>
          method.paymentType == this.purchase(this.uuids[0])?.paymentType
      );
    },

    orderDate() {
      const date = this.purchase(this.uuids[0])?.createdAt;
      if (!date) return null;

      return new Intl.DateTimeFormat("ru-RU", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).format(new Date(date));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.order-page {
  height: max-content;
  width: 100%;
  border-radius: $sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      text-align: start;
      white-space: nowrap;
      font-size: 1.188rem;
      font-weight: 400;
    }
  }
}

.order-page--loadung {
  .title-wrapper {
    .title {
      @include gradient-animation;
      color: transparent;
    }
    .order-date {
      @include gradient-animation;
      color: transparent;
      min-width: 120px;
      height: 20px;
    }
  }

  .release {
    .title {
      @include gradient-animation;
      color: transparent;
      width: 100px;
      height: 16px;
      margin-bottom: 5px;
    }

    .artists {
      @include gradient-animation;
      color: transparent;
      min-width: 150px;
      height: 14px;
    }
  }
}

.orders-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray;
  margin-bottom: 10px;

  .order-wrapper {
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }
  }
}

.order-info {
  display: flex;
  justify-content: space-between;
  color: $crow-black;
  cursor: pointer;
  position: relative;

  .release-info {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .go-to-release-btn {
    position: absolute;
    left: 50px;
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .release-info {
      opacity: 0;
    }

    .go-to-release-btn {
      opacity: 1;
    }
  }
}

.release {
  display: flex;
  align-items: center;
  cursor: pointer;

  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .artists {
    font-size: 14px;
    white-space: nowrap;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cover {
    width: 50px;
    height: 50px;
    background: gainsboro;
    margin-right: 10px;
    border-radius: 7px;
    overflow: hidden;
  }
}
</style>
