const name = 'Drafts';

const headers = [
  {
    field: 'cover_uuid',
    isImage: true,
  },
  {
    field: 'title',
    itemCellsAlign: 'left',
  },
  {
    field: 'artist.name',
    title: 'Artist',
    localizer: 'artists',
    itemCellsAlign: 'center',
  },
  {
    field: 'release_date',
    title: 'Date',
    localizer: 'date',
    headerCellAlign: 'center',
    itemCellsAlign: 'center',
    isSortable: true,
  },
  {
    field: 'delete_button',
    title: 'Actions',
    localizer: 'actions',
    headerCellAlign: 'center',
    itemCellsAlign: 'center',
    isButton: true,
  },
];

export { name, headers };
