<template>
  <div class="subtitle">
    <SvgIcon type="mdi" :path="icons.info" :size="20" />
    <span>
      <slot />
    </span>
  </div>
</template>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiInformationVariantCircleOutline } from "@mdi/js";

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      icons: {
        info: mdiInformationVariantCircleOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
.subtitle {
  margin-top: 20px;
  color: $text_color;
  display: flex;
  align-content: center;

  svg {
    margin-right: 10px;
  }

  span {
    width: calc(100% - 30px);

    a {
      color: $text_color;
      font-weight: 500;
    }
  }
}
</style>