<template>
  <div :class="['product', { 'product--loading': loading }]">
    <div class="icon--loading" v-if="loading"></div>
    <div class="icon" v-else>
      <SvgIcon
        type="mdi"
        :path="icons.clockFast"
        :size="31"
        v-if="product.name == 'MYLABEL_EARLY_DATE'"
      />
      <SvgIcon
        type="mdi"
        :path="icons.copyright"
        :size="31"
        v-if="product.name == 'MYLABEL_CUSTOM_PC_LINE'"
      />
      <SvgIcon
        type="mdi"
        :path="icons.producer"
        :size="31"
        v-if="product.name == 'MYLABEL_PRODUCER_ARTIST'"
      />
    </div>
    <div v-if="loading" class="product-price-wrapper--loading">
      <div class="info"></div>
      <div class="price"></div>
    </div>
    <div class="product-price-wrapper" v-else>
      <div class="info" v-if="product.name == 'MYLABEL_EARLY_DATE'">
        Релиз будет опубликован в приоритетном порядке
      </div>
      <div class="info" v-if="product.name == 'MYLABEL_CUSTOM_PC_LINE'">
        Права на релиз принадлежат вам
      </div>
      <div class="info" v-if="product.name == 'MYLABEL_PRODUCER_ARTIST'">
        В вашем релизе есть артист с ролью "Продюсер"
      </div>
      <div class="price">{{ product.amount }}$</div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiClockFast,
  mdiCopyright,
  mdiAccountStar,
  mdiLoading,
} from "@mdi/js";
import Chip from "../Chip.vue";

export default {
  components: {
    SvgIcon,
    Chip,
  },
  props: {
    product: Object,
    loading: Boolean,
    showPrice: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        clockFast: mdiClockFast,
        copyright: mdiCopyright,
        producer: mdiAccountStar,
        loading: mdiLoading,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.icon--loading {
  width: 50px;
  height: 50px;
  @include gradient-animation;
  color: transparent;
}

.product--loading {
  .icon--loading {
    display: block;
  }

  .product-price-wrapper--loading {
    width: calc(100% - 70px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      height: 20px;
      min-width: 60%;
      @include gradient-animation;
      color: transparent;
    }

    .price {
      height: 20px;
      min-width: 40px;
      @include gradient-animation;
      color: transparent;
    }
  }
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      circle at left top,
      rgba(233, 163, 120, 0.3),
      rgba(236, 226, 219, 0.5)
    );
    border: 2px solid rgba(233, 163, 120, 0.2);
    border-radius: 5px;
    color: $orange;
  }

  .product-price-wrapper {
    width: calc(100% - 70px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info {
    max-width: 400px;
  }

  .price {
    margin-left: 15px;
    font-weight: 500;
  }
}
</style>
