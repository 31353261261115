<template>
  <section class="main">
    <div
      class="main-section-load"
      v-if="dataLoading"
    >
      <LoadingLogo />
    </div>
    <section
      class="main__releases"
      v-else
    >
      <Table
        :name="$t('latest_releases')"
        :headers="tableHeaders"
        :items="allReleasesList"
        :numbering="false"
        v-if="allReleasesList.length"
      />
      <DataPlaceholder v-else
        :title="$t('no_releases_placeholder_title')"
        :description="$t('no_releases_placeholder_description')"
        :button-text="$t('no_releases_placeholder_action')"
        :button-redirect-url="'/upload'"
      />
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataPlaceholder from '../components/DataPlaceholder.vue';
import Table from '../components/Table.vue';
import LoadingLogo from '../components/LoadingLogo.vue';
import { headers as tableHeaders } from '@/utilities/tableStructures/allReleases.js';

export default {
  components: {
    LoadingLogo,
    DataPlaceholder,
    Table,
  },
  data: function () {
    return {
      dataLoading: false,
      tableName: 'Last Releases',
      tableHeaders,
    };
  },
  mounted () {
    this.dataLoading = true;
    this.fetchAllReleases().finally(() => {
      this.dataLoading = false;
    });
  },
  methods: {
    ...mapActions(['fetchAllReleases']),
  },
  computed: {
    ...mapGetters(['releases', 'acceptedReleases', 'notDraftReleases', 'artistByUuid']),

    allReleasesList: function () {
      const allReleasesList = this.notDraftReleases.map(release => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
          artist: {
            name: this.artistByUuid(release.artist_uuid) ? this.artistByUuid(release.artist_uuid).name : 'Artist',
          },
          redirectUrl: `/releases/${release.uuid}`,
          stats_button: {
            callback: () => this.$router.push(`/statistics/${release.uuid}`),
            icon: 'stats',
          },
        };
      });

      const sortedList = [...allReleasesList].sort((a, b) =>
        a.updated_at.localeCompare(b.updated_at)
      ).reverse().slice(0, 3);

      return sortedList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.main__releases{
  @include section;
  width: 100%;
  height: min-content;
  margin-bottom: 30px;
  margin-bottom: 22px;
  box-sizing: border-box;
  overflow-x: auto;
}

.main-section-load{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.main{
  box-sizing: border-box;
  width: 100%;
}

.main__section{
  margin-bottom: 30px;
}

.main__subscription-offer{
  padding: 30px 35px;

  border-radius:$sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;
}

.main__subscription-offer-header{
  margin: 0;
  margin-bottom: 35px;

  font-size: 1.25rem;
  font-weight: 400;
}

.main__subscriptions-offer-item{
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 28%;

  &::after{
    position: absolute;
    top:18%;
    left: 105%;

    width: 15px;
    height: 2px;

    content: '';

    border-radius: 1px;
    background: $dark-gray;
  }

  &::before{
    position: absolute;
    top:18%;
    left: 105%;

    width: 15px;
    height: 2px;

    content: '';
    transform: rotateZ(90deg);

    border-radius: 1px;
    background: $dark-gray;
  }

  &:last-child::before{
    display: none;
  }

  &:last-child::after{
    display: none;
  }
}

.main__subscriptions-offer-item-img{
  max-width: 165px;
  max-height: 70px;
}

.main__subscriptions-offer-wrapper{
  display: flex;
  justify-content: space-between;
}

.main__subscriptions-offer-item-text{
  flex-grow: 1;

  margin: 0;

  color:$dark-gray;

  font-size: 1rem;
}

.main__subscriptions-offer-item-img-wrapper{
  position: relative;

  display: flex;
  align-items: center;

  height: 120px;
  margin-bottom: 38px;

  &::after{
    position: absolute;
    top: 100%;

    width: 84px;
    height: 1px;

    content:'';

    background: $dark-gray;
  }
}
</style>
