<template>
  <button class="subscription-order">
    <div class="icon">
      <img
        :src="require('@/assets/landing/icons/SERVICE.png')"
        alt=""
        class="colored-image"
      />
    </div>
    <div class="info">
      <h3 class="name">{{ name }} SUBSCRIPTION</h3>
      <div class="expires">
        <p class="subtitle">{{ period }}</p>
      </div>
    </div>
  </button>
</template>
<script>
// import { mdiChevronDoubleRight } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      icons: {},
    };
  },
  methods: {},
  computed: {
    name() {
      if (this.subscription) {
        switch (this.subscription.name) {
          case "PREMIUM_1Y":
            return "PREMIUM";
          case "PREMIUM_1M":
            return "PREMIUM";
          default:
            return subscription.name;
        }
      }
    },
    period() {
      if (this.subscription) {
        const pluralRules = new Intl.PluralRules("ru-RU");
        const rule = pluralRules.select(this.subscription.timeRangeCount);

        const rules = {
          HOUR: {
            one: "час",
            few: "часа",
            many: "часов",
          },
          DAY: {
            one: "день",
            few: "дня",
            many: "дней",
          },
          MONTH: {
            one: "месяц",
            few: "месяца",
            many: "месяцев",
          },
          YEAR: {
            one: "год",
            few: "года",
            many: "лет",
          },
        };
        console.log(rules, this.subscription.timeRange);
        //timeRange HOUR, DAY, MONTH, YEAR
        const word = rules[this.subscription.timeRange][rule];
        return `На ${this.subscription.timeRangeCount} ${word}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.subscription-order {
  display: flex;
  align-items: center;
  padding: 7px;
  border: 2px solid $red;
  background: transparent;
  border-radius: 12px;
  width: 100%;
  color: $red;
  cursor: pointer;
  transition: 0.5s;

  .icon {
    transition: 0.5s;
    height: 60px;
    img {
      transform: translateY(-10px);
      width: 60px;
      // height: 60px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    position: relative;

    .name {
      padding: 0;
      margin: 0;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .subtitle {
      padding: 0;
      margin: 0;
      font-size: 14px;
      text-align: start;
    }
  }
}
</style>