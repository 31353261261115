<template>
  <div class="section">
    <transition name="fade">
      <Notification
        style="margin-bottom: 10px;"
        v-if="release?.status == 'DENIED'"
        :notification="deniedNotification"
      />
    </transition>
    <transition name="fade">
      <Notification
        style="margin-bottom: 10px;"
        v-if="release?.status == 'DRAFT'"
        :notification="draftNotification"
      />
    </transition>

    <audio ref="player" @timeupdate="updatePlayingProgress">
      <source
        :src="release.tracks[playingTrackIndex].fileSrc"
        v-if="playingTrackIndex !== null"
        type="audio/wav"
      />
    </audio>
    <div
      :class="[
        'release-preview',
        { 'release-preview__loading': isReleaseLoading },
      ]"
    >
      <div class="header">
        <Button transparent class="back-btn" @click="goToAllReleasesPage()">
          <slot name="pre-icon">
            <SvgIcon type="mdi" :path="icons.back" />
          </slot>
          {{ $t("all_releases") }}
        </Button>
        <div class="name-wrapper">
          <h3 class="name" v-if="release">{{ release.title }}</h3>
          <!-- <Button small gray class="download-btn">
            <template v-slot:pre-icon>
              <SvgIcon type="mdi" :size="20" :path="icons.download" />
            </template>
            {{ $t("download") }}
          </Button> -->
        </div>
      </div>

      <div class="data">
        <div class="meta-data">
          <div class="cover-wrapper">
            <div :class="['cover', { 'cover-loading': coverLoading }]">
              <img
                v-if="release"
                :src="release.coverCdnLink"
                alt=""
                @load="disableCoverLoading"
                @error="errorCoverLoading"
              />
            </div>
          </div>
          <div class="artists item">
            <h4 class="title">{{ $t("artists") }}</h4>
            <div class="artists-wrapper">
              <div
                class="artists-avatars"
                :style="`min-width:${15 * preparedArtists.length + 15}px`"
              >
                <div
                  v-for="(preparedArtist, index) in preparedArtists.slice(0, 4)"
                  :key="preparedArtist.uuid"
                  class="avatar"
                  :style="`left:${15 * (index - 1) + 15}px`"
                >
                  <img
                    :src="
                      preparedArtist.avatarCdnLink
                        ? preparedArtist.avatarCdnLink
                        : '/default-user.png'
                    "
                    alt=""
                  />
                </div>
              </div>
              <div class="artists-names">
                <div
                  class="name"
                  v-for="(preparedArtist, index) in preparedArtists.slice(0, 2)"
                  :key="preparedArtist.uuid"
                >
                  {{ preparedArtist.name
                  }}{{ preparedArtists.length > 1 && index < 1 ? "," : "" }}
                </div>
              </div>
              <Button
                small
                transparent
                gray
                class="more-btn"
                v-show="preparedArtists.length > 2"
              >
                and {{ preparedArtists.length - 2 }} more...
              </Button>
            </div>
          </div>
          <div class="release-date item">
            <h4 class="title">{{ $t("release_date") }}</h4>
            <p class="text">{{ releaseDate }}</p>
          </div>
          <div class="status item">
            <h4 class="title">{{ $t("status") }}</h4>
            <StatusChip
              v-if="release && !isReleaseLoading"
              :key="release.status"
              :status="release.status"
            />
            <p class="text" v-else>Error</p>
          </div>
          <div class="genre item">
            <h4 class="title">{{ $t("genre") }}</h4>
            <p class="text">
              {{
                release
                  ? primaryStyle(release.primary_music_style_id)?.name
                  : "Error"
              }}
            </p>
          </div>
          <div class="copyright item">
            <h4 class="title">{{ $t("copyright") }}</h4>
            <p class="text">
              {{ release?.copyright ? release?.copyright : "Error" }}
            </p>
          </div>
          <div class="language item">
            <h4 class="title">{{ $t("language") }}</h4>
            <p class="text">
              {{ release ? language(release.language_id).name : "Error" }}
            </p>
          </div>
          <div class="listen item">
            <Button
              small
              @click="playButtonSwitch"
              v-show="!isPlaying"
              :disabled="playBtnDisabled"
            >
              <template v-slot:pre-icon>
                <SvgIcon type="mdi" :path="icons.play" />
              </template>
              {{ $t("listen") }}
            </Button>
            <Button small @click="pause" v-show="isPlaying">
              <template v-slot:pre-icon>
                <SvgIcon type="mdi" :path="icons.pause" />
              </template>
              {{ $t("pause") }}
            </Button>
          </div>
        </div>
      </div>
      <div
        class="tracks"
        v-if="release && !isReleaseLoading && release.tracks?.length"
      >
        <Track
          :loading="track.loading"
          v-for="(track, index) in preparedTracks"
          :key="track.uuid"
          :track="track"
          :index="index + 1"
          :releaseStatus="release.status"
          :playing="playingTrackIndex == index && isPlaying"
          @loaded="loadedTrackData(track, $event)"
          @play="playTrack(index)"
          @pause="pause()"
          @error="errorWavDownloadHandler(track)"
        />
      </div>
      <!-- LOADING TRACK DATA -->
      <div class="tracks" v-if="isReleaseLoading">
        <Track :loading="true" v-for="i in 4" :key="i" :index="i" />
      </div>
      <!-- EMPTY TRACK LIST -->
      <div
        class="tracks"
        v-if="release && !isReleaseLoading && !release?.tracks.length"
      >
        <EmptyTrack
          :releaseStatus="release?.status"
          :releaseUuid="release?.uuid"
        />
      </div>
      <div class="market-data">
        <div class="data-item">
          <h4 class="title">UPC</h4>
          <p class="text">
            <span v-if="release && release.UPC">{{ release.UPC }}</span>
            <span v-else class="empty-text"
              >No data <SvgIcon type="mdi" :path="icons.question" />
              <div class="tooltip">
                {{ $t("preview.upc-empty-data") }}
              </div>
            </span>
          </p>
        </div>
        <div class="data-item">
          <h4 class="title">ISRC</h4>
          <p class="text">
            <span v-if="release && release.ISRC"> {{ release.ISRC }} </span>
            <span v-else class="empty-text"
              >No data <SvgIcon type="mdi" :path="icons.question" />
              <div class="tooltip">
                {{ $t("preview.isrc-empty-data") }}
              </div>
            </span>
          </p>
        </div>
        <div class="data-item">
          <h4 class="title">{{ $t("created_date") }}</h4>
          <p class="text">{{ createdAtDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.section {
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 21px 0px #0000001a;
  padding: 32px 32px 24px 32px;
  height: min-content;
}

.release-preview {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .name-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 80%;

      .name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        font-weight: 600;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .data {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .cover {
      min-width: 175px;
      width: 175px;
      height: 175px;
      border-radius: 16px;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .meta-data {
      width: 100%;
      height: 175px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "cover-wrapper artists artists artists"
        "cover-wrapper release-date status genre"
        "cover-wrapper copyright language listen";
      gap: 10px;

      .cover-wrapper {
        grid-area: cover-wrapper;
      }

      .item {
        max-width: 175px;
        .title {
          white-space: nowrap;
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #82868a;
          font-weight: 500;
          margin-bottom: 3px;
        }

        .text {
          max-width: 100%;
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          color: black;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .artists {
        grid-area: artists;
        max-width: 100%;

        .artists-wrapper {
          display: flex;
          align-items: center;

          .artists-avatars {
            position: relative;
            display: flex;
            height: 20px;

            .avatar {
              position: absolute;
              width: 20px;
              height: 20px;
              border-radius: 100%;
              border: 2px solid white;
              overflow: hidden;
              top: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .artists-names {
            display: flex;
            align-items: center;
            max-width: 70%;
            width: min-content;

            .name {
              white-space: nowrap;
              margin-right: 5px;
              font-size: 18px;
              color: black;
              font-weight: 600;
              width: max-content;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .more-btn {
            white-space: nowrap;
          }
        }
      }

      .listen {
        display: flex;
        align-items: center;
      }
    }
  }

  .tracks {
    padding: 20px 0px;
  }

  .market-data {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .data-item {
      .title {
        color: #82868a;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 3px;
      }

      .text {
        margin: 0;
        padding: 0;
        color: black;
        font-size: 18px;
        font-weight: 700;

        .empty-text {
          display: flex;
          align-items: center;
          color: #d6d6d6;
          user-select: none;
          position: relative;

          svg {
            margin-left: 5px;
          }

          .tooltip {
            display: none;
            color: #000000;
            position: absolute;
            background: rgba($color: white, $alpha: 0.5);
            backdrop-filter: blur(7px);
            border: 1px dotted rgba($color: #000000, $alpha: 0.5);
            top: -80px;
            left: -50%;
            width: 200px;
            border-radius: 3px;
            padding: 8px;
            font-size: 15px;
            font-weight: 500;
          }

          &:hover {
            .tooltip {
              display: block;
            }
          }
        }
      }
    }
  }

  .cover-loading {
    @include gradient-animation;

    img {
      display: none;
    }
  }

  &__loading {
    .header {
      width: 100%;
      .name-wrapper {
        height: 21px;
        border-radius: 4px;

        border-radius: 6px;
        @include gradient-animation;
        .name {
          display: none;
        }
        .download-btn {
          display: none;
        }
      }
    }

    .data {
      .cover {
        @include gradient-animation;

        img {
          display: none;
        }
      }

      .meta-data {
        .item {
          .title {
            @include gradient-animation;
            color: rgba(0, 0, 0, 0);
            width: min-content;
            margin-bottom: 3px;
          }

          .text {
            @include gradient-animation;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0);
            width: min-content;
          }
        }

        .artists {
          .artists-wrapper {
            @include gradient-animation;

            .avatar {
              display: none;
            }

            .artists-names {
              display: none;
            }

            .more-btn {
              display: none;
            }
          }
        }

        .listen {
          display: none;
        }
      }
    }

    .market-data {
      .data-item {
        .title {
          @include gradient-animation;
          width: min-content;
          white-space: nowrap;
          color: rgba(0, 0, 0, 0);
        }
        .text {
          @include gradient-animation;
          height: 18px;
          width: min-content;
          min-width: 100px;
          color: rgba(0, 0, 0, 0);
          white-space: nowrap;

          .empty-text {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .release-preview {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .name-wrapper {
        display: none;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;

      .cover {
        min-width: 130px;
        width: 130px;
        height: 130px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .meta-data {
        margin: 20px 0px 0px 0px;
        width: 100%;
        height: min-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "cover-wrapper release-date"
          "cover-wrapper status"
          "artists artists"
          "language copyright"
          "genre listen";
        gap: 10px 0px;

        .cover-wrapper {
          grid-area: cover-wrapper;
        }

        .item {
          max-width: 175px;
          .title {
            white-space: nowrap;
            margin: 0;
            padding: 0;
            font-size: 16px;
            color: #82868a;
            font-weight: 500;
            margin-bottom: 3px;
          }

          .text {
            max-width: 100%;
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .artists {
          grid-area: artists;
          max-width: 100%;

          .artists-wrapper {
            display: flex;
            align-items: center;

            .artists-avatars {
              position: relative;
              display: flex;
              height: 20px;

              .avatar {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 2px solid white;
                overflow: hidden;
                top: 0;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .artists-names {
              display: flex;
              align-items: center;
              max-width: 60%;
              width: min-content;

              .name {
                white-space: nowrap;
                margin-right: 5px;
                font-size: 15px;
                color: black;
                font-weight: 500;
                width: min-content;
                min-width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .more-btn {
              margin-left: 10px;
              white-space: nowrap;
            }
          }
        }

        .listen {
          display: flex;
          align-items: center;
        }
      }
    }

    .tracks {
      padding: 20px 0px;
    }

    .market-data {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .data-item {
        .title {
          color: #82868a;
          white-space: nowrap;
          margin: 0;
          padding: 0;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 3px;
        }

        .text {
          margin: 0;
          padding: 0;
          color: black;
          font-size: 18px;
          font-weight: 700;

          .empty-text {
            display: flex;
            align-items: center;
            color: #d6d6d6;
            user-select: none;
            position: relative;

            svg {
              margin-left: 5px;
            }

            .tooltip {
              display: none;
              color: #000000;
              position: absolute;
              background: rgba($color: white, $alpha: 0.5);
              backdrop-filter: blur(7px);
              border: 1px dotted rgba($color: #000000, $alpha: 0.5);
              top: -80px;
              left: -50%;
              width: 200px;
              border-radius: 3px;
              padding: 8px;
              font-size: 15px;
              font-weight: 500;
            }

            &:hover {
              .tooltip {
                display: block;
              }
            }
          }
        }
      }
    }

    .cover-loading {
      @include gradient-animation;

      img {
        display: none;
      }
    }

    &__loading {
      .header {
        width: 100%;
        .name-wrapper {
          height: 21px;
          border-radius: 4px;

          border-radius: 6px;
          @include gradient-animation;
          .name {
            display: none;
          }
          .download-btn {
            display: none;
          }
        }
      }

      .data {
        .cover {
          @include gradient-animation;

          img {
            display: none;
          }
        }

        .meta-data {
          .item {
            .title {
              @include gradient-animation;
              color: rgba(0, 0, 0, 0);
              width: min-content;
              margin-bottom: 3px;
            }

            .text {
              @include gradient-animation;
              white-space: nowrap;
              color: rgba(0, 0, 0, 0);
              width: min-content;
            }
          }

          .artists {
            .artists-wrapper {
              @include gradient-animation;

              .avatar {
                display: none;
              }

              .artists-names {
                display: none;
              }

              .more-btn {
                display: none;
              }
            }
          }

          .listen {
            display: none;
          }
        }
      }

      .market-data {
        .data-item {
          .title {
            @include gradient-animation;
            width: min-content;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0);
          }
          .text {
            @include gradient-animation;
            height: 18px;
            width: min-content;
            min-width: 100px;
            color: rgba(0, 0, 0, 0);
            white-space: nowrap;

            .empty-text {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/RedButton.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiChevronLeft,
  mdiCloudDownloadOutline,
  mdiPlay,
  mdiHelpCircleOutline,
  mdiPause,
} from "@mdi/js";
import StatusChip from "../StatusChip.vue";
import Track from "./Track.vue";
import EmptyTrack from "./EmptyTrack.vue";
import Notification from "@/components/Notification.vue";

//TODO: сообщение о причине отказа сверху
export default {
  components: {
    Button,
    SvgIcon,
    StatusChip,
    Track,
    EmptyTrack,
    Notification,
  },
  props: {
    releaseUuid: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        back: mdiChevronLeft,
        download: mdiCloudDownloadOutline,
        play: mdiPlay,
        pause: mdiPause,
        question: mdiHelpCircleOutline,
      },
      release: null,
      preparedArtists: [],
      preparedTracks: [],
      isReleaseLoading: true,
      coverLoading: true,
      playingTrackIndex: null,
      isPlaying: false,
    };
  },
  methods: {
    ...mapActions([
      "fetchLanguages",
      "fetchMusicStyles",
      "fetchArtist",
      "fetchRelease",
      "fetchFile",
      "getFileCdnLink",
      "unmoderateRelease"
    ]),

    goToAllReleasesPage() {
      this.$router.push("/releases");
    },

    loadedTrackData(track, { blob }) {
      const fileSrc = window.URL.createObjectURL(blob);
      track.fileSrc = fileSrc;
    },

    errorWavDownloadHandler(track) {
      track.fileSrcError = true;
    },

    playButtonSwitch() {
      if (this.playingTrackIndex == null) {
        this.playingTrackIndex = -1;
        this.nextTrack();
      } else {
        if (this.$refs.player.paused) {
          this.play();
        } else {
          this.pause();
        }
      }
    },

    playTrack(index) {
      if (this.release.tracks[index].fileSrcError) {
        this.nextTrack();
        return false;
      }
      if (this.playingTrackIndex == index) {
        if (this.$refs.player.paused) {
          this.play();
        } else {
          this.pause();
        }
        return;
      }

      this.playingTrackIndex = index;
      try {
        this.$refs.player.load();
      } catch {
        this.pause();
      } finally {
        this.play();
      }
    },

    updatePlayingProgress() {
      const currentTime = this.$refs.player.currentTime;
      const progress = currentTime
        ? (this.$refs.player.currentTime / this.$refs.player.duration) * 100
        : 0;
      if (progress >= 100) {
        this.nextTrack();
      }
      this.playingProgress = progress;
    },

    nextTrack() {
      this.pause();

      if (this.playingTrackIndex < this.release.tracks.length - 1) {
        this.playingTrackIndex++;
        if (this.release.tracks[this.playingTrackIndex]?.fileSrcError) {
          this.nextTrack();
          return false;
        } else {
          this.$refs.player.load();
          this.play();
        }
      } else {
        this.playingTrackIndex = 0;
      }
    },

    play() {
      this.$refs.player.play();
      this.isPlaying = !this.$refs.player.paused;
    },

    pause() {
      this.$refs.player.pause();
      this.isPlaying = !this.$refs.player.paused;
    },

    disableCoverLoading() {
      this.coverLoading = false; // Изображение загружено, скрываем индикатор
    },
    errorCoverLoading() {
      this.release.coverCdnLink = require("@/assets/placeholder.png");
      this.disableCoverLoading;
    },

    async prepareArtist(artistUuid) {
      const preparedArtist = (await this.fetchArtist(artistUuid)).data;

      if (preparedArtist.avatar_uuid) {
        const artistAvatar = (
          await this.getFileCdnLink(preparedArtist.avatar_uuid)
        ).data;
        preparedArtist.avatarCdnLink = artistAvatar;
      }

      return preparedArtist;
    },
  },
  async mounted() {
    this.$refs.player.volume = 0.2;
    this.isReleaseLoading = true;
    this.release = (await this.fetchRelease(this.releaseUuid)).data;
    const coverCdnLink = (await this.getFileCdnLink(this.release.cover_uuid))
      .data;
    this.release.coverCdnLink = coverCdnLink;
    for (const artist of this.release.artists) {
      const preparedArtist = await this.prepareArtist(artist.artist_uuid);
      this.preparedArtists.push(preparedArtist);
    }
    await this.fetchMusicStyles();
    await this.fetchLanguages();

    this.isReleaseLoading = false;

    for (const track of this.release.tracks) {
      const prepapedTrackArtists = [];
      track.loading = true;
      this.preparedTracks.push(track);
      for (const artist of track.artists) {
        const preparedArtist = {
          ...artist,
          ...(await this.prepareArtist(artist.artist_uuid)),
        };
        prepapedTrackArtists.push(preparedArtist);
      }
      track.artists = prepapedTrackArtists;
      track.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      "artistRoles",
      "composerRoles",
      "primaryStyles",
      "secondaryStyles",
      "language",
      "languages",
      "primaryStyle",
      "secondaryStyle",
    ]),
    deniedNotification() {
      return {
        heading: this.$t("release_rejected_by_the_moderator"),
        message: `${this.$t("message_from_moderator")}: "${
          this.release.deny_reason
        }"`,
        actionButtonText: this.$t("back_to_draft"),
        style: "warning",
        opened: true,
        actionCallback: () => {
          this.unmoderateRelease(this.release.uuid)
            .then(() => {
              this.$router.push(`/drafts/${this.release.uuid}`);
            })
            .catch((e) => {
              const notification = {
                id: new Date(),
                heading: "An error occurred during release unmoderating",
                message: e.message,
                style: "failure",
                actionCallback: () => {
                  this.$router.push({ name: "Support" });
                },
              };

              this.createNotification(notification);
            });
        },
      };
    },

    draftNotification(){
      return {
        heading: this.$t("release_is_draft_notification"),
        actionButtonText: this.$t("back_to_draft"),
        style: "info",
        opened: true,
        actionCallback: () => {
          this.unmoderateRelease(this.release.uuid)
            .then(() => {
              this.$router.push(`/drafts/${this.release.uuid}`);
            })
            .catch((e) => {
              const notification = {
                id: new Date(),
                heading: "An error occurred during release unmoderating",
                message: e.message,
                style: "failure",
                actionCallback: () => {
                  this.$router.push({ name: "Support" });
                },
              };

              this.createNotification(notification);
            });
        },
      };
    },

    playBtnDisabled() {
      return (
        this.release?.tracks.find((track) => track.loading) ||
        !this.release?.tracks.find((track) => track.fileSrc)
      );
    },
    releaseDate() {
      const date = new Date(this.release?.release_date);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    createdAtDate() {
      const date = new Date(this.release?.created_at);

      // Получаем текущий язык через i18n
      const currentLocale = this.$i18n.locale;

      // Определяем формат в зависимости от языка
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };

      // Если язык русский, используем "ru-RU", иначе - "en-GB"
      const locale = currentLocale === "ru" ? "ru-RU" : "en-GB";

      return date.toLocaleDateString(locale, options);
    },
    playerPlaying() {
      return this.$refs.player?.paused;
    },
  },
};
</script>