<template>
  <div class="deny-readon-notify-wrapper">
    <Notification
      v-if="releaseData.denyReason"
      class="noti"
      :notification="deniedNotification"
    />
  </div>

  <Modal
    :visibility="isPaymentModal"
    @switch="paymentModalSwitch"
    :heading="'Payment details'"
  >
    <Payment @payment="handlePayment" />
  </Modal>

  <section class="upload">
    <LoadingLogo v-if="isDataLoading" />
    <div class="upload__wrapper" v-else>
      <div class="upload__section upload__section--flow flow">
        <div class="flow__section">
          <div
            :class="[
              'flow__step',
              'flow__step--main',
              currentStep < 1 ? 'flow__step--inactive' : '',
            ]"
          >
            <h1 class="flow__heading">
              {{ $t("main_data") }}
            </h1>
            <div class="flow__required required">
              <h2 class="required__title">
                {{ $t("required_fields") }}
              </h2>
              <div class="required__group">
                <h3 class="required__subtitle">
                  {{ $t("release") }}
                </h3>
                <ul class="required__fields">
                  <li
                    :class="[
                      'required__field',
                      cover && cover.src ? '' : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("cover") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      releaseData.title ? '' : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("release_name") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      releaseData.language !== undefined
                        ? ''
                        : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("language") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      releaseData.genre !== undefined
                        ? ''
                        : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("genre") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      releaseData.copyright ? '' : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("copyright") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      releaseData.releaseDate
                        ? ''
                        : 'required__field--inactive',
                    ]"
                  >
                    {{ $t("release_date") }}
                  </li>
                </ul>
              </div>
              <div class="required__group">
                <h3 class="required__subtitle">
                  {{ $t("artists") }}
                </h3>
                <ul class="required__fields">
                  <li
                    :class="['required__field', 'required__field--inactive']"
                    v-if="!releaseData.artists || !releaseData.artists.length"
                  >
                    {{ $t("at_least_1_artist") }}
                  </li>
                  <li
                    :class="[
                      'required__field',
                      artist.name && artist.name.length
                        ? ''
                        : 'required__field--inactive',
                    ]"
                    v-for="artist in releaseData.artists"
                    :key="artist.id"
                  >
                    {{ artist.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flow__main main main--general" @mouseenter="gotoStep(1)">
            <h2 class="main__heading" ref="releaseTypes">
              {{ $t("release_type") }}
            </h2>
            <div class="main__types">
              <button
                :class="[
                  'main__type',
                  releaseData.type == releaseType.value
                    ? 'main__type--selected'
                    : '',
                ]"
                v-for="releaseType in releaseTypes"
                :key="releaseType.name"
                @click="selectReleaseType(releaseType.value)"
              >
                {{ releaseType.name }}
              </button>
            </div>
            <h2 class="main__heading">
              {{ $t("general_info") }}
            </h2>
            <div class="main__form">
              <div class="main__row main__row--center">
                <FileInput
                  :disabled="isCoverLoading"
                  class="main__cover cover"
                  @upload="loadCover"
                  :accepts="['image/jpeg', 'image/png']"
                >
                  <div class="cover__wrapper">
                    <transition name="fade">
                      <div class="cover__loading" v-show="isCoverLoading">
                        <LoadingLogo />
                      </div>
                    </transition>
                    <img
                      :src="cover.src"
                      alt="Cover"
                      class="cover__background"
                      v-if="cover.src.length"
                    />
                    <div
                      v-show="!isCoverLoading"
                      :class="[
                        'cover__input',
                        cover.src.length ? 'cover__input--uploaded' : '',
                      ]"
                    >
                      <svg>
                        <use
                          v-bind="{
                            'xlink:href':
                              require('@/assets/icons/all_icons.svg') +
                              '#flash',
                          }"
                        />
                      </svg>
                      <p>
                        {{ $t("choose_or_drop_cover_here") }}
                      </p>
                    </div>
                  </div>
                </FileInput>
                <div class="main__column">
                  <div class="main__row main__row--with-button">
                    <InputWithLabel
                      class="main__input"
                      :label="$t('release_name')"
                      :placeholder="$t('release_name')"
                      v-model="releaseData.title"
                      :validations="validations.release.title"
                      @set-input-ref="setInputRef"
                    />
                  </div>
                  <div class="main__row main__row--with-button">
                    <InputWithLabel
                      class="main__input"
                      :label="$t('release_title_version')"
                      :placeholder="$t('title_version_placeholder')"
                      v-model="releaseData.titleVersion"
                      :validations="validations.release.titleVersion"
                    />
                  </div>
                </div>
              </div>
              <div class="main__row">
                <InputWithLabel
                  class="main__input"
                  :label="$t('publisher')"
                  :placeholder="$t('publisher')"
                  v-model="releaseData.publisher"
                  :validations="validations.release.publisher"
                />
                <!-- :lock-reason="$t('see_details')"
                @unlock="offeringProduct = Products.MYLABEL_CUSTOM_PC_LINE" -->
                <InputWithLabel
                  class="main__input"
                  :label="$t('copyright')"
                  :placeholder="$t('copyright')"
                  v-model="releaseData.copyright"
                  :validations="validations.release.copyright"
                  :offer="isCustomCopyright ? 'Услуга Copyright' : ''"
                />
              </div>
              <div class="main__row">
                <DropdownInput
                  class="main__dropdown"
                  :items="genres"
                  :label="$t('genre')"
                  :placeholder="$t('genre')"
                  v-model="releaseData.genre"
                  :validations="validations.release.genre"
                />
                <!-- <DropdownInput class="main__dropdown"
                  :items="subgenres"
                  :label="'Subgenre'"
                  placeholder="Subgenre"
                  v-model="releaseData.subgenre"
                  ref="subgenreInput"
                  :validations="validations.release.subgenre"
                /> -->
              </div>
              <div class="main__row">
                <DropdownInput
                  class="main__dropdown"
                  :items="languagesList"
                  :label="$t('language')"
                  :placeholder="$t('language')"
                  v-model="releaseData.language"
                  :validations="validations.release.language"
                />

                <ReleaseDatePicker
                  v-model="releaseData.releaseDate"
                  class="main__date"
                  :minDate="minimalReleaseDate"
                  :maxDate="maximumReleaseDate"
                />
              </div>
            </div>
            <h2 class="main__heading">
              {{ $t("artists_on_release") }}
            </h2>
            <div class="main__artists artists">
              <template v-if="artists.length">
                <div class="artists__header">
                  <InputWithLabel
                    class="artists__search"
                    v-model="artistsSearchQuery"
                    :placeholder="`${$t('search')}...`"
                  />
                  <button class="artists__action" @click="editingArtist = {}">
                    <svg>
                      <use
                        v-bind="{
                          'xlink:href':
                            require('@/assets/icons/all_icons.svg') +
                            '#plus-translucent',
                        }"
                      />
                    </svg>
                    <span>
                      {{ $t("new_artist") }}
                    </span>
                  </button>
                </div>
                <div class="artists__list">
                  <ArtistCard
                    v-for="artist in sortedAndFilteredArtists"
                    :key="artist.uuid"
                    :artist="artist"
                    :selectable="true"
                    :selected="!!getReleaseArtist(artist.uuid)"
                    :subtitle="getReleaseArtist(artist.uuid)?.role"
                    @select="selectingArtist = artist"
                    @unselect="removeArtist(artist.uuid)"
                    @edit="editingArtist = artist"
                  />
                </div>
              </template>
              <DataPlaceholder
                v-else
                :title="$t('no_artists_placeholder_title')"
                :description="$t('no_artists_placeholder_description_upload')"
                :button-text="$t('no_artists_placeholder_action')"
                @action="editingArtist = {}"
                :minimized="true"
              />
            </div>
          </div>
        </div>
        <div class="flow__section">
          <div
            :class="[
              'flow__step',
              'flow__step--upload',
              currentStep < 2 ? 'flow__step--inactive' : '',
            ]"
          >
            <h1 class="flow__heading">
              {{ $t("uploading") }}
            </h1>
            <div class="flow__required required">
              <h2 class="required__title">
                {{ $t("missing_fields") }}
              </h2>
              <div class="required__no-missing" v-if="!incompleteTracks.length">
                {{ $t("will_appear_here") }}
              </div>
              <div
                class="required__group"
                v-for="track in incompleteTracks"
                :key="track.id"
              >
                <h3 class="required__subtitle">
                  {{ track.title.length ? track.title : $t("untitled") }}
                </h3>
                <ul class="required__fields">
                  <Transition name="required-swipe">
                    <li class="required__field" v-if="!track.artistName.length">
                      {{ $t("artist") }}
                    </li>
                  </Transition>
                  <Transition name="required-swipe">
                    <li class="required__field" v-if="!track.title.length">
                      {{ $t("title") }}
                    </li>
                  </Transition>
                  <Transition name="required-swipe">
                    <li class="required__field" v-if="track.genre == undefined">
                      {{ $t("genre") }}
                    </li>
                  </Transition>
                  <Transition name="required-swipe">
                    <li
                      class="required__field"
                      v-if="track.language == undefined"
                    >
                      {{ $t("language") }}
                    </li>
                  </Transition>
                </ul>
              </div>
            </div>
          </div>
          <div
            :class="['flow__main', 'main', 'main--upload']"
            @mouseenter="gotoStep(2)"
          >
            <Transition name="scale-fade">
              <div class="lock" v-if="!releaseInfoIsValid">
                <div class="lock__icon-wrapper">
                  <svg class="lock__icon">
                    <use
                      v-bind="{
                        'xlink:href':
                          require('@/assets/icons/all_icons.svg') + '#lock',
                      }"
                    />
                  </svg>
                </div>
                <p class="lock__message">
                  {{ $t("upload_lock_message") }}
                </p>
              </div>
            </Transition>
            <div
              class="lock lock__loading"
              v-if="!releaseInfoIsValid && isCoverLoading"
            >
              <div class="lock__icon-wrapper">
                <LoadingLogo />
              </div>
              <p class="lock__message">
                {{ $t("release_cover_is_uploading") }}
              </p>
            </div>
            <FileInput
              :class="[
                'main__audiofile',
                'audiofile',
                this.tracksData.length ? '' : 'audiofile--large',
              ]"
              @upload="loadTrack"
              :accepts="['audio/wav']"
            >
              <div class="audiofile__wrapper">
                <svg>
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') + '#flash',
                    }"
                  />
                </svg>
                <p>
                  {{ $t("choose_or_drop_track_here") }}
                </p>
              </div>
            </FileInput>
            <div class="main__tracks" v-show="releaseInfoIsValid">
              <div
                class="main__track"
                v-for="(track, index) in tracksData"
                :key="track.file"
                draggable="true"
                @dragstart="trackStartDrag($event, index)"
                @drop="trackOnDrop($event, index)"
                @dragover.prevent
                @dragenter.prevent
              >
                <svg
                  :class="`main__trackdots ${
                    editingTrackIndex == index ? 'main__trackdots--hidden' : ''
                  }`"
                >
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') + '#four-dots',
                    }"
                  />
                </svg>
                <TrackForm
                  class="main__trackform"
                  v-model="tracksData[index]"
                  :editing="editingTrackIndex == index"
                  :artists-list="artistsList"
                  :artist-roles="artistRolesList"
                  :composer-roles="composerRolesList"
                  :languages="languagesList"
                  :genres="genres"
                  :subgenres="subgenres"
                  :loading-progress="track.loadingProgress"
                  @remove="removeTrackRequest(index)"
                  @edit="startEditingTrack(index)"
                  @save="createOrUpdateTrack(index)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flow__section">
          <div
            :class="[
              'flow__step',
              'flow__step--preview',
              currentStep < 3 ? 'flow__step--inactive' : '',
            ]"
          >
            <h1
              :class="[
                'flow__heading',
                currentStep < 3 ? 'flow__heading--inactive' : '',
              ]"
            >
              {{ $t("finishing_touches") }}
            </h1>
            <div class="flow__memo memo">
              <div class="memo__body">
                <h2 class="memo__heading">
                  {{ $t("dont_forget") }}
                </h2>
                <p class="memo__text">
                  {{ $t("auto_saves_releases") }}
                </p>
                <RedButton
                  class="memo__button"
                  @click="this.$router.replace('/drafts')"
                >
                  {{ $t("see_my_drafts") }}
                </RedButton>
              </div>
              <p class="memo__footer">
                {{ $t("continue_in_drafts") }}
              </p>
            </div>
          </div>
          <div
            class="flow__main main main--preview preview"
            @mouseenter="gotoStep(3)"
          >
            <div class="preview__title">
              {{
                releaseData.title.length
                  ? releaseData.title
                  : $t("enter_the_title")
              }}
            </div>
            <div class="preview__data">
              <div class="preview__cover">
                <img :src="cover.src" alt="Cover" v-if="cover.src" />
                <svg v-else>
                  <use
                    v-bind="{
                      'xlink:href':
                        require('@/assets/icons/all_icons.svg') +
                        '#mylabel-symbol',
                    }"
                  />
                </svg>
              </div>
              <div class="preview__info">
                <div class="preview__text">
                  <p class="preview__info-title">
                    {{ $t("artist") }}
                  </p>
                  <p class="preview__info-subtitle">
                    {{ artistNames.length ? artistNames : $t("add_an_artist") }}
                  </p>
                  <p class="preview__info-title">
                    {{ $t("release_date") }}
                  </p>
                  <p class="preview__info-subtitle">
                    {{ formattedReleaseDateString }}
                  </p>
                </div>
                <button
                  class="preview__play"
                  @click="playButtonSwitch"
                  v-if="tracksData.length"
                >
                  <svg>
                    <use
                      v-bind="{
                        'xlink:href':
                          require('@/assets/icons/all_icons.svg') +
                          ($refs.player && $refs.player.paused
                            ? '#play'
                            : '#pause'),
                      }"
                    />
                  </svg>
                  <span>
                    {{ $t("listen") }}
                  </span>
                </button>
              </div>
            </div>
            <div class="preview__tracks">
              <div
                v-for="(track, index) in tracksData"
                :key="track.src"
                @click="playTrack(index)"
                :class="[
                  'preview__track',
                  'track',
                  !track.file ? 'track--loading' : '',
                ]"
                :style="
                  playingTrackIndex == index
                    ? `--loadingProgress: calc(${playingProgress.toFixed(
                        3
                      )}% - 24px);`
                    : ''
                "
              >
                <div class="track__background" />
                <div class="track__info">
                  <div
                    class="track__general"
                    :title="`${track.title} - ${track.artistName}`"
                  >
                    <span class="track__index">
                      {{ index + 1 }}
                    </span>
                    <span class="track__title">
                      {{ track.title }}
                    </span>
                    <span class="track__artist">
                      {{ ` - ${track.artistName}` }}
                    </span>
                  </div>
                  <span class="track__duration">
                    {{ track.duration }}
                  </span>
                </div>
              </div>
            </div>
            <div class="preview__error" v-if="v$.$silentErrors?.length">
              <p>
                {{ $t("release_data_filled_incorrectly") }}
              </p>
            </div>
            <div class="preview__error" v-else-if="!tracksData?.length">
              <p>
                {{ $t("no_added_tracks_on_release") }}
              </p>
            </div>
            <div class="preview__error" v-else-if="trackDataIsIncompleted">
              <p>
                {{ $t("save_track_data") }}
              </p>
            </div>
            <div class="preview__error" v-else-if="areAnyTracksLoading">
              <p>
                {{ $t("tracks_loading") }}
              </p>
            </div>
            <div class="preview__error" v-else-if="areAllTracksSaved">
              <p>
                {{ $t("tracks_are_not_saved") }}
              </p>
            </div>
            <RedButton class="preview__submit" v-else-if="paymentLoading">
              <template v-slot:pre-icon>
                <SvgIcon
                  type="mdi"
                  :path="icons.loading"
                  class="loading-anim"
                />
              </template>
              Загрузка
            </RedButton>
            <RedButton
              class="preview__submit"
              @click="payAndSendToRelease"
              v-else-if="releaseCost > 0"
            >
              Оплатить и опубликовать релиз
            </RedButton>
            <RedButton class="preview__submit" @click="sendToRelease" v-else>
              {{ $t("send_to_release") }}
            </RedButton>
          </div>
        </div>
        <!-- COST -->
        <div class="flow__section" v-if="releaseData.uuid">
          <div
            class="flow__main main main--preview preview"
            @mouseenter="gotoStep(3)"
          >
            <div
              :class="[
                'release-cost',
                { 'release-cost--loading': releaseNeededLoading },
              ]"
            >
              <div class="price-wrapper">
                <div class="title-wrapper">
                  <div class="title">Стоимость</div>
                  <div class="subtitle" v-show="releaseNeededProducts.length">
                    {{ selectedServiceSentence }}
                  </div>
                  <div class="subtitle" v-show="!releaseNeededProducts.length">
                    Ваш релиз будет опубликован бесплатно
                  </div>
                </div>
                <div class="price" v-show="releaseNeededProducts.length">
                  {{ releaseCost }}$
                </div>
                <div class="price" v-show="!releaseNeededProducts.length">
                  Бесплатно
                </div>
              </div>
              
              <div
                class="products"
                v-if="releaseNeededProducts.length && !releaseNeededLoading"
              >
                <div
                  v-for="product in releaseNeededProducts"
                  :key="product.name"
                  class="product-wrapper"
                >
                  <ProductCard :loading="isOrderLoading" :product="product" />
                  <Chip
                    v-if="product.access"
                    :color="'green'"
                    small
                    quadrat
                    stroke
                    >Оплачено</Chip
                  >
                </div>
              </div>
              <div class="subscriptions" v-if="currentUserSubscription">
                <div class="subtitle">
                  Ваш текуший план:
                </div>
                <SubscriptionBage
                  disabled
                  :userSubscription="currentUserSubscription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="upload__notifications">
        <TransitionGroup name="squeeze-slide" mode="out-in">
          <Notification
            v-for="noti in notifications"
            :key="noti.id"
            :notification="noti"
            class="upload__notification"
          />
        </TransitionGroup>
      </div>
    </div>
    <audio ref="player" @timeupdate="updatePlayingProgress">
      <source
        :src="tracksData[playingTrackIndex].fileSrc"
        v-if="playingTrackIndex !== null"
        type="audio/wav"
      />
    </audio>
    <Modal
      :visibility="isCropperModal"
      @switch="(value) => (isCropperModal = value)"
      :heading="$t('crop_cover')"
    >
      <div class="upload__cropper">
        <CoverCropper :src="cover.src" @save="coverCropHandler" />
      </div>
    </Modal>
    <Modal
      :visibility="editingArtist"
      :heading="
        editingArtist?.uuid
          ? $t('artist_form_edit_artist')
          : $t('artist_form_create_new_artist')
      "
      @switch="editingArtist = null"
    >
      <div class="artist-modal">
        <ArtistForm :artist="editingArtist" @submit="editingArtist = null" />
      </div>
    </Modal>
    <Modal
      :visibility="selectingArtist"
      :heading="
        selectingArtist && $t('add_on_release', { name: selectingArtist.name })
      "
      @switch="saveArtistSelection"
    >
      <div class="artist-modal">
        <DropdownInput
          :items="artistRolesList"
          :label="$t('role_on_release')"
          :placeholder="$t('select_artists_role')"
          v-model="selectingArtistRole"
          :searchable="false"
        />
        <RedButton @click="saveArtistSelection">
          {{ $t("add") }}
        </RedButton>
      </div>
    </Modal>
  </section>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiClockFast,
  mdiCopyright,
  mdiAccountStar,
  mdiLoading,
} from "@mdi/js";
import RedButton from "@/components/RedButton.vue";
import GrayButton from "@/components/GrayButton.vue";
import FileInput from "@/components/FileInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import DropdownInput from "@/components/DropdownInput.vue";
import TrackForm from "@/components/TrackForm.vue";
import CoverCropper from "@/components/CoverCropper.vue";
import Modal from "@/components/Modal.vue";
import Notification from "@/components/Notification.vue";
import ArtistCard from "@/components/ArtistCard.vue";
import ArtistForm from "@/components/ArtistForm.vue";
import LoadingLogo from "@/components/LoadingLogo.vue";
import ProductModal from "@/components/ProductModal.vue";
import Order from "@/components/Order.vue";
import Payment from "@/components/Payment.vue";
import DataPlaceholder from "../components/DataPlaceholder.vue";
import validateCoverImage from "../utilities/validateCoverImage";
import ReleaseDatePicker from "@/components/ReleaseDatePicker.vue";
import Chip from "@/components/Chip.vue";
import ProductCard from "@/components/payments/ProductCard.vue";
import SubscriptionBage from '@/components/payments/SubscriptionBage.vue'

export default {
  components: {
    SvgIcon,
    RedButton,
    DataPlaceholder,
    GrayButton,
    TrackForm,
    FileInput,
    InputWithLabel,
    DropdownInput,
    Notification,
    CoverCropper,
    Modal,
    ArtistCard,
    ArtistForm,
    LoadingLogo,
    ProductModal,
    Order,
    ReleaseDatePicker,
    Chip,
    Payment,
    ProductCard,
    SubscriptionBage
  },
  data() {
    return {
      v$: useValidate(),

      isDataLoading: false,
      isCropperModal: false,
      isCoverLoading: false,

      offeringProduct: null,
      maxTrackFileSize: 100 * 1024 * 1024, // bytes
      currentStep: 1,
      editingArtist: null,
      selectingArtist: null,
      selectingArtistRole: null,
      artistsSearchQuery: "",

      cover: {
        uuid: "",
        file: null,
        src: "",
      },

      releaseData: {
        uuid: "",
        type: null,
        cover_uuid: "",
        title: "",
        titleVersion: "",
        artists: [],
        composers: [],
        copyright: "Mylabel",
        publisher: "MyLabel",
        genre: undefined,
        subgenre: undefined,
        language: undefined,
        releaseDate: "",
        denyReason: "",
      },
      tracksData: [],

      saveRequestTimeout: null,
      releaseNeededProductsTimeout: null,
      releaseNeededLoading: false,
      releaseNeededProducts: [],
      paymentLoading: false,

      playingProgress: 0,
      playingTrackIndex: null,
      editingTrackIndex: null,
      uploadCoverPromise: null,
      inputRef: null,

      icons: {
        loading: mdiLoading,
      },
    };
  },
  mounted() {
    this.formSetup();
    this.$refs.player.volume = 0.2;

    this.fetchLanguages();
    this.fetchMusicStyles();

    //check is its draft
    if (this.$route.params.uuid) {
      this.configureDraft();
    } else {
      this.fetchUserArtists();
    }

    this.fetchProducts();
  },
  methods: {
    handlePayment() {
      this.paymentLoading = true;
      try {
        const notification = {
          id: new Date(),
          heading: `The payment was successful. Thank you!`,
          style: "success",
          lifetime: 15000,
        };

        this.hidePaymentModal();
        this.createNotification(notification);
        setTimeout(() => {
          this.paymentLoading = false;
          this.sendToRelease();
        }, 5000);
      } catch (e) {
        this.paymentLoading = false;
      }
    },
    setInputRef(ref) {
      this.inputRef = ref;
      if (this.inputRef && this.inputRef.focus) {
        this.inputRef.focus(); // Устанавливаем фокус на инпут
      }
    },
    ...mapActions([
      "fetchLanguages",
      "fetchMusicStyles",

      "fetchUserArtists",

      "addArtistOnRelease",
      "removeArtistFromRelease",
      "addArtistOnTrack",
      "removeArtistFromTrack",

      "addComposerOnTrack",
      "removeComposerFromTrack",

      "fetchRelease",
      "createRelease",
      "updateRelease",
      "publishRelease",
      "deleteDraft",

      "uploadFile",
      "deleteFile",
      "fetchFile",

      "createTrack",
      "updateTrack",
      "deleteTrack",

      "createNotification",

      "showPaymentModal",
      "hidePaymentModal",
      "fetchUserProductAccess",
      "fetchReleaseNeededProducts",
      "fetchProducts",
      "createUserProduct",

      "updateModal",
      "toggleModal",
    ]),

    ...mapMutations(["setCartPurchases"]),

    validateCoverImage,

    selectReleaseDate(date) {
      this.releaseData.releaseDate = date;
    },

    getReleaseArtist(artistUuid) {
      return this.releaseData.artists.find((a) => a.uuid == artistUuid);
    },

    saveArtistSelection() {
      if (this.selectingArtist && this.selectingArtistRole) {
        this.addNewArtist(this.selectingArtist.uuid, this.selectingArtistRole);
      }

      this.selectingArtist = null;
      this.selectingArtistRole = null;
    },

    async coverCropHandler({ base64, binary }) {
      const isCoverValid = await this.validateCoverImage(base64);
      if (!isCoverValid) return;

      this.isCropperModal = false;
      this.setCover(binary, base64);

      if (this.releaseData.cover_uuid || this.releaseData.uuid) {
        this.updateReleaseCover();
      }
    },

    configureDraft() {
      const releaseUuid = this.$route.params.uuid;
      this.releaseData.uuid = releaseUuid;
      this.isDataLoading = true;
      this.fetchRelease(releaseUuid)
        .then((response) => {
          const release = response.data;

          this.cover = {
            src: "",
            file: null,
            uuid: release.cover_uuid,
          };
          this.releaseData = {
            uuid: release.uuid,
            artists: release.artists,
            language: release.language_id,
            title: release.title ?? undefined,
            titleVersion: release.title_version ?? undefined,
            genre: release.primary_music_style_id,
            subgenre: release.secondary_music_style_id,
            type: release.type,
            tracks: release.tracks,
            cover_uuid: release.cover_uuid,
            releaseDate: new Date(release.release_date),
            copyright: release.copyright,
            publisher: "MyLabel",
            denyReason: release.deny_reason,
          };

          if (!this.isValidReleaseDate()) {
            const modalData = {
              id: "warning",
              isVisible: true,
              heading: this.$t("please_note"),
              message: this.$t("update_release_date"),
            };
            this.updateModal({ id: "warning", data: modalData });

            this.releaseData.releaseDate = null;
          }

          this.fetchUserArtists().then(() => {
            this.releaseData.artists.forEach((a, index) => {
              const foundArtist = this.artists.find(
                (artist) => artist.uuid == a.artist_uuid
              );
              if (foundArtist) {
                this.releaseData.artists[index] = {
                  role: this.releaseData.artists[index].role,
                  uuid: foundArtist.uuid,
                  name: foundArtist.name,
                  spotifyID: foundArtist.spotify_id,
                  appleID: foundArtist.apple_id,
                };
              }
            });

            this.releaseData.tracks.forEach((track, i) => {
              track.artists.forEach((a, j) => {
                const foundArtist = this.artists.find(
                  (artist) => artist.uuid == a.artist_uuid
                );
                if (foundArtist) {
                  this.releaseData.tracks[i].artists[j] = {
                    ...this.releaseData.tracks[i].artists[j],
                    uuid: foundArtist.uuid,
                    name: foundArtist.name,
                    spotifyID: foundArtist.spotify_id,
                    appleID: foundArtist.apple_id,
                  };
                }
              });
            });
          });

          this.isCoverLoading = true;
          this.fetchFile(release.cover_uuid)
            .then((response) => {
              this.cover.src = window.URL.createObjectURL(response.data);
            })
            .finally(() => {
              this.isCoverLoading = false;
            });

          for (let i = 0; i < release.tracks.length; i++) {
            const track = release.tracks[i];
            const trackData = {
              uuid: track.uuid,
              file: undefined,
              fileSrc: "",
              fileUuid: track.wav_uuid,
              wav_uuid: track.wav_uuid,
              artistName: "",
              title: track.title,
              genre: track.primary_music_style_id,
              subgenre: track.secondary_music_style_id,
              language: track.language_id,
              previewStartSecond: track.preview_start_seconds,
              lyrics: track.lyrics,
              explicit: track.explicit,
              instrumental: track.instrumental,
              artists: track.artists,
              composers: track.composers,
            };
            this.tracksData[i] = trackData;

            this.fetchFile(track.wav_uuid).then((response) => {
              this.tracksData[i].file = response.data;
              this.tracksData[i].fileSrc = window.URL.createObjectURL(
                response.data
              );
            });
          }
        })
        .catch((e) => {
          this.$router.push({ name: "Drafts" });
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    sendToReleaseRequest() {
      const releaseUuid = this.releaseData.uuid;
      this.publishRelease(releaseUuid)
        .then((response) => {
          const releaseTitle = response.data.title;

          const notification = {
            id: new Date(),
            heading: `The "${releaseTitle}" was successfully submitted for moderation`,
            style: "success",
          };

          this.createNotification(notification);
          this.$router.push(`/releases/${releaseUuid}`);
        })
        .catch((e) => {
          const notification = {
            id: new Date(),
            heading: "An error occurred during release publishing",
            message: e.message,
            style: "failure",
            actionCallback: () => {
              this.$router.push({ name: "Support" });
            },
          };

          this.createNotification(notification);
        });
    },

    async payAndSendToRelease() {
      this.paymentLoading = true;

      try {
        this.setCartPurchases([]);
        await Promise.all(
          this.releaseNeededProducts
            .filter((product) => !product.access)
            .map(async (product) => {
              await this.createUserProduct(product.name);
            })
        );

        this.showPaymentModal();
      } catch (e) {
        const notification = {
          id: new Date(),
          heading: "An error occurred while creating the payment",
          message: e.message,
          style: "failure",
          actionCallback: () => {
            this.$router.push({ name: "Support" });
          },
        };

        this.createNotification(notification);
      } finally {
        this.paymentLoading = false;
      }
    },

    sendToRelease() {
      const releaseType = this.releaseData.type;
      const tracksQty = this.tracksData.length;

      const modalBaseProps = {
        isVisible: true,
        heading: this.$t("please_note"),
        actionCallback: () => {
          this.sendToReleaseRequest();
          this.toggleModal("warning");
        },
        actionText: this.$t("publish"),
      };

      if (tracksQty == 1 && ["EP", "ALBUM"].includes(releaseType)) {
        const modalData = {
          ...modalBaseProps,
          message: this.$t("release_type_with_one_track", {
            type: this.$t(releaseType),
          }),
          cancelCallback: () => {
            this.releaseData.type = "SINGLE";
            this.toggleModal("warning");
          },
          cancelText: this.$t("change_to_type", { type: this.$t("single") }),
        };
        this.updateModal({ id: "warning", data: modalData });
      } else if (tracksQty > 1 && releaseType == "SINGLE") {
        const modalData = {
          ...modalBaseProps,
          message: this.$t("single_with_multiple_tracks"),
          cancelCallback: () => {
            const element = this.$refs.releaseTypes;
            const y =
              element.getBoundingClientRect().top + window.pageYOffset - 20;

            window.scrollTo({ top: y, behavior: "smooth" });
            this.toggleModal("warning");
          },
          cancelText: this.$t("change"),
        };
        this.updateModal({ id: "warning", data: modalData });
      } else {
        this.sendToReleaseRequest();
      }
    },

    gotoStep(step) {
      this.currentStep = step;
    },

    formSetup() {
      this.releaseData.type = this.releaseTypes[0].value;
    },

    selectReleaseType(releaseType) {
      this.releaseData.type = releaseType;
    },

    generateNewID() {
      return new Date().getTime();
    },

    getDashFormattedDate(dateString) {
      const date = new Date(dateString);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      );
    },

    async setCover(file, fileSrc) {
      const src = fileSrc ?? (await this.loadFileSRC(file));

      this.cover.file = file;
      this.cover.src = src;
    },

    async uploadCover(coverFile) {
      this.deleteFile(this.releaseData.cover_uuid);
      this.releaseData.cover_uuid = "";
      this.uploadCoverPromise = new Promise((resolve, reject) => {
        this.isCoverLoading = true;
        this.uploadFileRequest(coverFile, () => {})
          .then((response) => {
            resolve(response.data.uuid);
          })
          .catch((e) => {
            const notification = {
              id: new Date(),
              heading: "An error occurred while uploading the cover",
              message: e.message,
              style: "failure",
              actionCallback: () => {
                this.$router.push({ name: "Support" });
              },
            };

            this.createNotification(notification);
            reject(e);
          })
          .finally(() => {
            this.isCoverLoading = false;
          });
      });

      return this.uploadCoverPromise;
    },

    async loadCover(file) {
      try {
        const src = await this.loadFileSRC(file);
        const isCoverValid = await this.validateCoverImage(src);
        if (isCoverValid) {
          this.setCover(file, src);
        }

        if (!isCoverValid) {
          const notification = {
            id: new Date(),
            heading: "Cover size doesn't match requirements",
            message:
              "The cover must be square and between 1440x1440 pixels and 3000x3000 pixels",
            style: "warning",
          };

          this.createNotification(notification);
          return;
        }

        this.isCropperModal = true;
      } catch (e) {
        console.error(e);
      }
    },

    async updateReleaseCover() {
      this.uploadCover(this.cover.file)
        .then((cover_uuid) => {
          this.releaseData.cover_uuid = cover_uuid;
        })
        .catch(() => {
          //TODO:
        });
      this.cover.file = null;
    },

    uploadFileRequest(file, loadProgress, abortController) {
      const formdata = new FormData();
      formdata.append("file", file);
      const uploadResult = this.uploadFile({
        formdata,
        loadProgress,
        abortController,
      });
      return uploadResult;
    },

    updatePlayingProgress() {
      const currentTime = this.$refs.player.currentTime;
      const progress = currentTime
        ? (this.$refs.player.currentTime / this.$refs.player.duration) * 100
        : 0;
      if (progress >= 100) {
        this.nextTrack();
      }
      this.playingProgress = progress;
    },

    playNextTrack() {
      if (this.playingTrackIndex + 1 < this.tracksData.length) {
        this.playTrack(this.playingTrackIndex + 1);
      } else {
        this.$refs.player.pause();
      }
    },

    playButtonSwitch() {
      if (!this.playingTrackIndex) this.playingTrackIndex = 0;

      if (this.$refs.player.paused) {
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }
    },

    playTrack(index) {
      if (this.playingTrackIndex == index) {
        if (this.$refs.player.paused) {
          this.$refs.player.play();
        } else {
          this.$refs.player.pause();
        }
        return;
      }

      this.playingTrackIndex = index;
      try {
        this.$refs.player.load();
      } catch {
        this.$refs.player.pause();
      } finally {
        this.$refs.player.play();
      }
    },

    checkFileSize(file) {
      if (file && file.size > this.maxTrackFileSize) {
        const maxTrackFileSizeInMB = this.maxTrackFileSize / (1024 * 1024);
        const notification = {
          id: new Date(),
          heading: this.$t("invalid_track_size"),
          message: this.$t("track_size_limit", { size: maxTrackFileSizeInMB }),
          style: "warning",
          lifetime: 5000,
        };
        this.createNotification(notification);

        return false;
      }

      return true;
    },

    async loadTrack(file) {
      const isFileSizeValid = this.checkFileSize(file);
      if (!isFileSizeValid) return;

      const id = this.generateNewID();
      const filename = file.name;
      const fileSrc = await this.loadFileSRC(file);

      const title = filename.substr(0, filename.lastIndexOf("."));

      const emptyTrack = {
        id,
        file,
        fileSrc,
        fileUuid: "",
        artistName: "",
        title,
        genre: undefined,
        subgenre: undefined,
        language: undefined,
        previewStartSecond: undefined,
        artists: [],
        composers: [],
        lyrics: "",
        explicit: false,
        instrumental: false,
        loadingProgress: 0,
      };

      const index = this.tracksData.push(emptyTrack) - 1;
      const track = this.tracksData[index];

      const fileUuid = await new Promise((resolve, reject) => {
        if (track.fileUuid.length) {
          resolve(track.fileUuid);
        } else {
          const loadProgress = (loadingProgress) => {
            this.tracksData[index].loadingProgress = loadingProgress;
          };
          const abortController = new AbortController();
          this.tracksData[index].abortController = abortController;
          return this.uploadFileRequest(
            track.file,
            loadProgress,
            abortController
          )
            .then((response) => {
              this.tracksData[index].loadingProgress = 101;
              resolve(response.data.uuid);
            })
            .catch((e) => reject(e));
        }
      }).catch((e) => {
        this.tracksData[index].error = this.$t("unable_upload_file");
      });

      this.tracksData[index].wav_uuid = fileUuid;
      this.tracksData[index].fileUuid = fileUuid;

      if (this.editingTrackIndex == null) {
        this.editingTrackIndex = this.tracksData.length - 1;
      }
    },

    getFileUrl(blob) {
      return URL.createObjectURL(blob);
    },

    loadFileSRC(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = () => {
          reject(fr.error);
        };
        fr.readAsDataURL(file);
      });
    },

    addNewArtist(artistUuid, selectingArtistRole) {
      const foundArtist = this.artists.find(
        (artist) => artist.uuid == artistUuid
      );
      if (foundArtist) {
        const artist = {
          uuid: foundArtist.uuid,
          name: foundArtist.name,
          spotifyID: foundArtist.spotify_id,
          appleID: foundArtist.apple_id,
          role: selectingArtistRole ?? undefined,
        };

        this.releaseData.artists.push(artist);
      }
    },

    removeArtist(uuid) {
      let artistIndex;
      const artist = this.releaseData.artists.find((a, index) => {
        artistIndex = index;
        return a.uuid == uuid;
      });

      this.releaseData.artists.splice(artistIndex, 1);

      const removePayload = {
        releaseUuid: this.releaseData.uuid,
        artistData: {
          artist_uuid: artist.uuid,
        },
      };

      this.removeArtistFromRelease(removePayload);
    },

    removeTrackByIndex(index) {
      this.tracksData.splice(index, 1);
    },

    removeTrackRequest(index) {
      const trackUuid = this.tracksData[index].uuid;

      if (this.tracksData[index]?.fileUuid) {
        this.deleteFile(this.tracksData[index].fileUuid);
      }

      if (!trackUuid) {
        this.tracksData[index].abortController?.abort();
        this.tracksData.splice(index, 1);
        return;
      }
      this.deleteTrack(this.tracksData[index]).then(() => {
        this.removeTrackByIndex(index);
      });
    },

    startEditingTrack(index) {
      this.editingTrackIndex = this.editingTrackIndex == index ? null : index;
    },

    async createOrUpdateTrack(index) {
      this.editingTrackIndex = null;
      const track = this.tracksData[index];

      const trackUuid = await new Promise((resolve, reject) => {
        const trackData = {
          wav_uuid: track.wav_uuid,
          language_id: track.language,
          title: track.title,
          title_version: "Title version",
          lyrics: track.lyrics?.length ? track.lyrics : undefined,
          primary_music_style_id: track.genre,
          preview_start_seconds: parseInt(track.previewStartSecond),
          explicit: track.explicit,
          instrumental: track.instrumental,
        };

        if (track.uuid) {
          const updateTrackPayload = {
            trackUuid: track.uuid,
            trackData,
          };

          this.updateTrack(updateTrackPayload)
            .then((response) => {
              this.tracksData[index].error = "";
              const notification = {
                id: new Date(),
                heading: `${this.$t("track_success_saved", {
                  name: this.tracksData[index].title,
                })}`,
                style: "success",
                lifetime: 5000,
              };

              this.createNotification(notification);
              resolve(response.uuid);
            })
            .catch((e) => {
              this.tracksData[index].error = "Incorrect track data";
              reject(e);
            });
        } else {
          if (!track.wav_uuid) return;

          const createTrackPayload = {
            releaseUuid: this.releaseData.uuid,
            trackData,
          };

          this.createTrack(createTrackPayload)
            .then((response) => {
              this.tracksData[index].uuid = response.uuid;
              this.tracksData[index].error = "";

              const notification = {
                id: new Date(),
                heading: `${this.$t("track_success_saved")}`,
                style: "success",
                lifetime: 5000,
              };

              this.createNotification(notification);
              resolve(response.uuid);
            })
            .catch((e) => {
              this.tracksData[index].error = "Incorrect track data";
              reject(e);
            });
        }
      });

      if (!trackUuid) return;
      this.tracksData[index].uuid = trackUuid;

      track.artists.forEach(async (artist) => {
        const artistFoundOnTrack = this.release(this.releaseData.uuid)
          .tracks.find((t) => t.uuid == track.uuid)
          ?.artists?.find((a) => a.artist_uuid == artist.uuid);

        if (!artistFoundOnTrack) {
          const payload = {
            trackUuid,
            artistData: {
              role: artist.role,
              artist_uuid: artist.uuid,
            },
          };
          this.addArtistOnTrack(payload);
        }
      });

      track.composers.forEach(async (composer) => {
        const composerFoundOnTrack = this.release(this.releaseData.uuid)
          .tracks.find((t) => t.uuid == track.uuid)
          ?.composers?.find((c) => c.uuid == composer.uuid);

        if (!composerFoundOnTrack) {
          const payload = {
            trackUuid,
            composerData: {
              role: composer.role,
              name: composer.name,
              surname: composer.surname,
              patronymic: composer.patronymic || undefined,
            },
          };
          this.addComposerOnTrack(payload);
        }
      });
    },

    trackStartDrag(e, index) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("dragIndex", index);
    },

    trackOnDrop(e, index) {
      const dragIndex = e.dataTransfer.getData("dragIndex");
      [this.tracksData[dragIndex], this.tracksData[index]] = [
        this.tracksData[index],
        this.tracksData[dragIndex],
      ];
    },

    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },

    async createOrUpdateRelease() {
      if (!this.releaseData.cover_uuid && this.cover.file) {
        this.updateReleaseCover(this.cover.file);
      }

      const releaseData = {
        cover_uuid: this.releaseData.cover_uuid || undefined,
        title_version: this.releaseData.titleVersion || undefined,
        language_id: this.releaseData.language || undefined,
        type: this.releaseData.type || undefined,
        primary_music_style_id: this.releaseData.genre || undefined,
        // secondary_music_style_id: this.releaseData.subgenre,
        release_date: this.releaseData.releaseDate || undefined,
        title: this.releaseData.title || undefined,
        copyright: this.releaseData.copyright || undefined,
      };

      if (this.releaseData.uuid.length) {
        const updatePayload = {
          releaseData,
          releaseUuid: this.releaseData.uuid,
          type: this.tracksData.length > 1 ? this.releaseData.type : "SINGLE",
        };
        await this.updateRelease(updatePayload);
      } else {
        await this.createRelease(releaseData).then((response) => {
          this.releaseData.uuid = response.uuid;

          const route = `/drafts/${response.uuid}`;
          this.changeRouteSilently(route);
        });
      }

      this.releaseData.artists.forEach(async (artist) => {
        const artistFoundOnRelease = this.release(
          this.releaseData.uuid
        ).artists.find((a) => a.artist_uuid == artist.uuid);
        if (!artistFoundOnRelease) {
          const payload = {
            releaseUuid: this.releaseData.uuid,
            artistData: {
              role: artist.role,
              artist_uuid: artist.uuid,
            },
          };
          this.addArtistOnRelease(payload);
        }
      });
    },

    releaseNeededProductsRequest() {
      clearTimeout(this.releaseNeededProductsTimeout);
      this.releaseNeededProductsTimeout = setTimeout(() => {
        this.releaseNeededLoading = true;
        this.fetchReleaseNeededProducts(this.releaseData.uuid)
          .then(async (response) => {
            if (!this.products || !this.products.length)
              await this.fetchProducts();

            const neededProductNames = response.data;

            this.releaseNeededProducts = await Promise.all(
              neededProductNames.map(async (productName) => {
                const product = {
                  name: productName,
                  access: false,
                  amount: this.products.find(
                    (product) => product.name == productName
                  ).amount,
                  error: false,
                };
                try {
                  const response = await this.fetchUserProductAccess(
                    productName
                  );
                  if (response.status == 200) {
                    product.access = true;
                  }
                } catch (e) {
                  if (e.response.status == 400) {
                    product.access = false;
                  } else {
                    product.access = false;
                    product.error = true;
                  }
                }
                return product;
              })
            );
          })
          .finally(() => {
            this.releaseNeededLoading = false;
          });
      }, 1000);
    },

    setSaveRequestTimeout() {
      clearTimeout(this.saveRequestTimeout);
      this.saveRequestTimeout = setTimeout(() => {
        this.createOrUpdateRelease().then(() => {
          this.releaseNeededProductsRequest();
        });
      }, 1000);
    },

    changeRouteSilently(path) {
      history.pushState({}, null, path);
    },

    isValidReleaseDate() {
      const minDate = this.minimalReleaseDate;
      const maxDate = this.maximumReleaseDate;
      const releaseDate = this.releaseData.releaseDate;

      return releaseDate >= minDate && releaseDate <= maxDate;
    },
  },
  computed: {
    ...mapGetters([
      "release",
      "artists",
      "notifications",

      "artistRoles",
      "composerRoles",
      "languages",
      "primaryStyles",
      "secondaryStyles",

      "isPaymentModal",
      "products",
      "currentUserSubscription"
    ]),

    selectedServiceSentence() {
      const pluralRules = new Intl.PluralRules("ru-RU");
      const rule = pluralRules.select(this.releaseNeededProducts.length);

      const words = {
        one: "услугу",
        few: "услуги",
        many: "услуг",
      };

      const word = words[rule] || "услуг";
      return `Вы выбрали ${this.releaseNeededProducts.length} ${word}`;
    },

    releaseCost() {
      if (this.releaseNeededProducts.length)
        return this.releaseNeededProducts
          .filter((product) => !product.access)
          .reduce((total, product) => total + product.amount, 0);
      else return 0;
    },

    isCustomCopyright() {
      return this.releaseData.copyright != "Mylabel";
    },

    deniedNotification() {
      return {
        heading: this.$t("release_rejected_by_the_moderator"),
        message: `${this.$t("message_from_moderator")}: "${
          this.releaseData.denyReason
        }"`,
        style: "warning",
        opened: true,
      };
    },

    // проверка все ли треки прикреплены к релизу
    trackDataIsIncompleted() {
      return this.tracksData.some((t) => !t.uuid); //TODO: must be return this.tracksData.some((t) => !t.uuid) without &&false
    },

    areAnyTracksLoading() {
      const isTrackLoading = (t) => !t.file || !t.fileUuid;
      return this.tracksData.some((t) => isTrackLoading(t));
    },

    areAllTracksSaved() {
      const isTrackSaved = (t) => !t.uuid;
      return this.tracksData.some((t) => isTrackSaved(t));
    },

    releaseTypes() {
      return [
        {
          name: this.$t("single"),
          value: "SINGLE",
        },
        {
          name: this.$t("album"),
          value: "ALBUM",
        },
        {
          name: this.$t("ep"),
          value: "EP",
        },
      ];
    },

    releaseInfoIsValid() {
      const titleValid =
        this.releaseData.title && this.releaseData.title.length >= 3;
      const titleVersionValid = this.releaseData.titleVersion
        ? this.releaseData.titleVersion.length >= 3
        : true;
      const publisherValid =
        this.releaseData.publisher && this.releaseData.publisher.length >= 3;
      const copyrightValid =
        this.releaseData.copyright && this.releaseData.copyright.length >= 3;
      const genreValid = !!this.releaseData.genre;
      const languageValid = !!this.releaseData.language;
      const releaseDateValid = this.isValidReleaseDate();
      const releaseArtistsValid = Boolean(
        this.releaseData.artists && this.releaseData.artists.length
      );
      const releaseCoverValid = Boolean(this.releaseData.cover_uuid);

      const valid =
        titleValid &&
        titleVersionValid &&
        publisherValid &&
        copyrightValid &&
        genreValid &&
        languageValid &&
        releaseDateValid &&
        releaseArtistsValid &&
        releaseCoverValid;

      return valid;
    },

    validations() {
      return {
        release: {
          title: {
            required,
            minLength: minLength(3),
          },
          titleVersion: {
            minLength: minLength(3),
          },
          publisher: {
            required,
            minLength: minLength(3),
          },
          copyright: {
            required,
            minLength: minLength(3),
          },
          genre: { required },
          subgenre: {},
          language: { required },
          releaseDate: { required },
        },
      };
    },

    sortedAndFilteredArtists() {
      const query = this.artistsSearchQuery.toLowerCase().trim();

      if (!query) return this.sortedArtists;

      return this.sortedArtists.filter((artist) => {
        const selectedArtist = this.releaseData.artists.find(
          (a) => a.uuid == artist.uuid
        );
        if (selectedArtist) return true;

        const artistName = artist.name.toLowerCase().trim();
        return artistName.includes(query);
      });
    },

    sortedArtists() {
      const sortedArtists = [...this.artists]
        .sort((a, b) => a.updated_at?.localeCompare(b.updated_at))
        .reverse()
        .sort((a, b) => {
          const artist1 = this.releaseData.artists.find(
            (artist) => artist.uuid == a.uuid
          );
          const artist2 = this.releaseData.artists.find(
            (artist) => artist.uuid == b.uuid
          );

          if (artist1 && artist2) {
            return 0;
          } else if (artist1) {
            return -1;
          } else {
            return 1;
          }
        });
      return sortedArtists;
    },

    genres() {
      if (!this.primaryStyles) {
        return [
          {
            value: "No genres",
          },
        ];
      }

      return this.primaryStyles.map((style) => {
        return {
          id: style.musicStyleId,
          value: this.$t(`genres.${style.name}`),
        };
      });
    },

    subgenres() {
      const subgenres = this.secondaryStyles(this.releaseData.genre);
      if (!subgenres || !subgenres.length) {
        return [
          {
            value: "No subgenres",
          },
        ];
      }

      return subgenres.map((style) => ({
        id: style.musicStyleId,
        value: this.$t(`genres.${style.name}`),
      }));
    },

    languagesList() {
      const languagesList = this.languages.map((language) => {
        return {
          id: language.languageId,
          value: this.$t(`languages.${language.name}`),
        };
      });
      return languagesList;
    },

    artistRolesList() {
      if (!this.artistRoles) {
        return [];
      }

      return Object.entries(this.artistRoles).map((role) => {
        return {
          id: role[0],
          value: this.$t(`artist_roles.${role[1]}`),
          subtitle: role[1] == "PRODUCER" ? "Платная роль" : "",
        };
      });
    },

    composerRolesList() {
      if (!this.composerRoles) {
        return [];
      }

      return Object.entries(this.composerRoles).map((role) => {
        return {
          id: role[0],
          value: this.$t(`composer_roles.${role[1]}`),
        };
      });
    },

    artistsList() {
      if (!this.artists || !this.artists.length) {
        return [];
      }

      return this.artists.map((artist) => {
        return {
          id: artist.uuid,
          value: artist.name,
        };
      });
    },

    artistNames() {
      return this.releaseData.artists
        .filter((artist) => artist.name && artist.name.length)
        .map((artist) => artist.name)
        .join(", ");
    },

    minimalReleaseDate() {
      const delay = 3;
      const date = new Date();
      date.setDate(date.getDate() + delay);
      date.setHours(0, 0, 0, 0);
      return date;
    },

    maximumReleaseDate() {
      const date = new Date();
      date.setDate(date.getDate() + 365);
      date.setHours(0, 0, 0, 0);
      return date;
    },

    formattedReleaseDateString() {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = this.releaseData.releaseDate;
      if (!this.isValidDate(date)) {
        return this.$t("set_the_date");
      }
      const dateString = date.toLocaleDateString("en-US", options);
      return dateString;
    },

    incompleteTracks() {
      const tracks = this.tracksData.filter(
        (track) =>
          Object.values(track).filter(
            (value) => value == null || value == undefined || value == ""
          ).length
      );
      return tracks;
    },
  },
  watch: {
    releaseData: {
      deep: true,
      handler() {
        this.setSaveRequestTimeout();
      },
    },
    "releaseData.releaseDate": {
      deep: true,
      handler() {
        if (!this.isValidReleaseDate()) {
          this.releaseData.releaseDate = null;
        }
      },
    },
    tracksData: {
      deep: true,
      handler() {
        this.releaseNeededProductsRequest();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.release-cost {
  .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-size: 31px;
      font-weight: 500;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .subtitle {
    color: #82868a;
    font-weight: 300;
  }

  .products {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .product-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}

.release-cost--loading {
  .title {
    -webkit-animation: gradient-move 2s ease infinite;
    -moz-animation: gradient-move 2s ease infinite;
    animation: gradient-move 2s ease infinite;

    background: linear-gradient(90deg, #f6f6f6, #dddddd);
    background-size: 400% 400%;
    color: transparent;
    width: 200px;
    border-radius: 3px;
  }

  .subtitle {
    -webkit-animation: gradient-move 2s ease infinite;
    -moz-animation: gradient-move 2s ease infinite;
    animation: gradient-move 2s ease infinite;

    background: linear-gradient(90deg, #f6f6f6, #dddddd);
    background-size: 400% 400%;
    color: transparent;
    border-radius: 3px;
  }

  .price-wrapper {
    .price {
      -webkit-animation: gradient-move 2s ease infinite;
      -moz-animation: gradient-move 2s ease infinite;
      animation: gradient-move 2s ease infinite;

      background: linear-gradient(90deg, #f6f6f6, #dddddd);
      background-size: 400% 400%;
      color: transparent;
      border-radius: 3px;
    }
  }
}

.deny-readon-notify-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;

  .noti {
    width: 50%;
    max-width: 680px;
    transform: translateX(40px);
  }
}

.squeeze-slide-enter-active,
.squeeze-slide-leave-active {
  overflow: hidden;

  transition: all 0.32s ease-in-out;
}

.squeeze-slide-enter-from,
.squeeze-slide-leave-to {
  max-height: 0px;

  transform: translateY(-20px) scale(0.8);

  opacity: 0;
}
.squeeze-slide-enter-to,
.squeeze-slide-leave-from {
  max-height: 300px;
}

.required-swipe-leave-to,
.required-swipe-enter-from {
  transform: translate(-30px);

  opacity: 0;
}

.scale-fade-enter-active,
.scale-fade-leave-active {
  transition: 0.4s;
}

.scale-fade-enter-from,
.scale-fade-leave-to {
  transform: scale(0.9);

  opacity: 0;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 20px 16px;
  margin-bottom: 32px;

  &__wrapper {
    display: flex;
    justify-content: center;

    width: 100%;
    max-width: 1516px;
  }

  &__section {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  &__notifications {
    position: sticky;
    top: 16px;

    display: flex;
    flex-direction: column;

    width: 500px;
    height: max-content;
    padding: 0 16px;

    gap: 16px;
  }

  // &__notification {}

  &__cropper {
    padding: 16px;
  }
}

.lock {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border-radius: inherit;
  background: white;

  &__icon {
    width: 32px;
    height: 32px;

    fill: #828282;
    stroke: #828282;
  }

  &__icon-wrapper {
    margin-bottom: 16px;
  }

  &__message {
    color: #828282;

    font-weight: 700;
  }

  &__loading {
    .lock__icon-wrapper {
      svg {
        width: 70px;
        height: 70px;

        fill: #e8e8e8;
        stroke: #828282;
        stroke-width: 4px;
      }
    }
  }
}

.main {
  position: relative;

  width: 100%;
  max-width: 680px;

  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 21px 0px #0000001a;

  &--general {
    padding: 24px 24px 26px 24px;
  }

  &--upload {
    padding: 36px 34px 34px;
  }

  &--preview {
    padding: 32px 32px 24px 32px;
  }

  &__heading {
    padding-left: 8px;

    font-size: 1.125rem;
    font-weight: 600;
  }

  &__types {
    display: flex;

    margin-top: 8px;
    margin-bottom: 24px;
    padding-left: 16px;

    gap: 52px;
  }

  &__type {
    padding: 0;
    padding-top: 10px;

    cursor: pointer;

    border: none;
    background: none;

    font-size: 1rem;
    font-weight: 400;

    color: $black;

    &--selected {
      position: relative;

      font-weight: 600;

      &::before {
        position: absolute;
        top: 0;
        left: calc(50% - 3px);

        width: 5.2px;
        height: 5.2px;

        content: "";

        border-radius: 100%;
        background: $red;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    margin-top: 16px;
    margin-bottom: 24px;

    gap: 20px;
  }

  &__artists {
    margin-top: 8px;
  }

  &__input {
    width: 100%;
  }

  &__row {
    display: flex;

    gap: 16px;

    & > * {
      height: min-content;
    }

    &--center {
      align-items: center;
    }

    &--with-button {
      gap: 10px;

      button {
        height: 40px;
        padding: 0;

        cursor: pointer;
        transition: 0.2s;
        white-space: nowrap;

        color: $red;
        border: none;
        border-radius: 8px;
        outline-color: rgba($color: $red, $alpha: 0.3);
        outline-offset: 4px;
        background: none;

        &:hover {
          transition: 0.2s;
          transform: scale(1.04);
        }

        &:active {
          transform: scale(0.95);
        }

        &:focus-visible {
          transform: scale(0.95);
        }
      }
    }
  }

  &__column {
    display: flex;
    flex: 1;
    flex-direction: column;

    gap: 20px;
  }

  &__artist {
    display: flex;
    flex-direction: column;

    padding-top: 16px;

    border-top: 1px solid $gray;

    gap: 20px;
  }

  // &__cover { }

  &__audiofile {
    margin-bottom: 32px;
  }

  &__tracks {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    gap: 18px;
  }

  &__track {
    display: flex;

    gap: 12px;

    svg {
      width: 15px;
      height: 15px;

      cursor: grab;

      fill: #bbbbbb;
    }
  }

  &__trackdots {
    max-width: 15px;
    max-height: 15px;
    width: 100%;
    height: 100%;
    transition: 0.2s;

    cursor: pointer;

    &--hidden {
      max-width: 0px;
      max-height: 0px;
      transition: 0.2s;
    }
  }

  &__trackform {
    max-width: calc(100% - (12px + 15px));
  }
}

.artists {
  margin-top: 8px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;

    gap: 8px;
  }

  &__search {
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.2s;
    white-space: nowrap;

    color: $red;
    border: none;
    background: none;

    font-size: 1rem;
    font-weight: 600;
    line-height: 100%;

    gap: 6px;

    svg {
      width: 12px;
      height: 12px;

      fill: $red;
    }

    &:hover {
      transition: 0.2s;
      transform: scale(1.04);
    }
  }

  &__list {
    display: grid;

    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 8px;
  }
}
.audiofile {
  &--large {
    .audiofile__wrapper {
      height: 240px;
      margin-bottom: -16px;

      transition: 0.3s;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 150px;

    transition: 0.2s;
    transition: 0.3s;

    border: 1px dashed #82868a;
    border-radius: 24px;

    gap: 16px;

    svg {
      width: 40px;
      height: 40px;

      fill: #82868a;
    }

    p {
      text-align: center;

      color: #82868a;

      font-size: 1rem;
      font-weight: 400;
    }

    &:hover {
      transition: background 0.3s;

      background: #f4f4f4;
    }
  }

  &__loading {
    svg {
      fill: #e2e2e2;
      stroke: #82868a;
      stroke-width: 6px;
    }
  }
}

.flow {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-left: 30px;

  gap: 32px;

  &__section {
    display: flex;
    justify-content: flex-end;

    width: 100%;

    gap: 72px;

    &:not(:last-child) {
      .flow__step::after {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;

        width: 1px;
        height: calc(100% + 80px);

        content: "";
        transition: 0.3s;

        background: $gray;
      }
    }
  }

  &__memo {
    width: 296px;
    margin-top: 32px;
  }

  &__step {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 32px;
    margin-bottom: 16px;

    &--inactive {
      &::after {
        transition: 0.3s;

        background: $gray !important;
      }
    }

    // &--main { }
    // &--upload { }
    &--preview {
      margin-right: -30px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 185px;
    height: 50px;

    transition: 0.3s;

    border-radius: 16px;
    background: white;
    box-shadow: 0px 0px 21px 0px #0000001a;

    font-size: 1.125rem;
    font-weight: 400;

    &--inactive {
      transition: 0.5s;

      color: #82868a;
      background: #e4e4e4;
      box-shadow: none;
    }
  }

  &__required {
    position: sticky;
    top: 24px;

    width: 236px;
    margin-top: 32px;
  }
}

.required {
  overflow: hidden;

  box-sizing: border-box;
  padding: 28px 32px 24px 32px;

  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 0px #0000001a;

  &__no-missing {
    text-align: center;
  }

  &__title {
    margin-bottom: 20px;

    text-align: center;

    font-size: 1.125rem;
    font-weight: 700;
  }

  &__subtitle {
    overflow: hidden;

    max-width: 100%;
    margin-bottom: 10px;

    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 1rem;
    font-weight: 600;
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;

    gap: 8px;
  }

  &__field {
    position: relative;

    padding-left: 22px;

    transition: 0.3s;

    font-size: 15px;
    font-weight: 400;

    &--inactive {
      transition: 0.3s;

      color: #aeaeae;

      &::before {
        background: #d7d7d7 !important;
      }
    }

    &::before {
      position: absolute;
      top: calc(50% - 3px);

      width: 6px;
      height: 6px;
      margin-left: -14px;

      content: "";
      transition: 0.3s;

      border-radius: 100%;
      background: $red;
    }
  }
}

.subscriptions{
  margin-top: 10px;
  .subtitle{
    margin-bottom: 10px;
  }
}

.memo {
  &__body {
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 28px 32px;

    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px #0000001a;
  }

  &__heading {
    margin-bottom: 8px;

    text-align: center;

    font-size: 1.125rem;
    font-weight: 700;
  }

  &__text {
    margin-bottom: 16px;

    font-size: 15px;
    font-weight: 400;

    span {
      color: $red;

      font-size: 15px;
      font-weight: 500;
    }
  }

  &__button {
    width: 100%;

    font-size: 1rem;
    font-weight: 600;
  }

  &__footer {
    text-align: center;

    color: #a5a5a5;

    font-size: 15px;
    font-weight: 400;
  }
}

@keyframes wave {
  0% {
    transform: translateY(-100%) scaleX(1);
  }
  50% {
    transform: translateY(0) scaleX(1.1);
  }
  100% {
    transform: translateY(100%) scaleX(1);
  }
}

.cover {
  display: inline-block;

  width: 180px;
  max-width: 180px;
  height: 180px;
  max-height: 180px;

  border-radius: 24px;

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    border-radius: 24px;

    svg {
      position: absolute;
      width: 70px;
      height: 70px;
      stroke: rgb(229, 229, 229);
      stroke-width: 5;
    }
  }

  &__loading::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: blur(8px);
    animation: wave 5s infinite;
  }

  &__background {
    position: absolute;

    width: 100%;
    height: 100%;

    transition: all 0.2s;

    border-radius: 24px;

    object-fit: cover;
  }

  &__input {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    transition: 0.2s;

    border-radius: 24px;

    gap: 16px;

    svg {
      width: 40px;
      height: 40px;

      fill: #82868a;
    }

    p {
      text-align: center;

      color: #82868a;

      font-size: 1rem;
      font-weight: 400;
    }

    &--uploaded {
      transition: 0.3s;

      opacity: 0%;

      svg {
        width: 40px;
        height: 40px;

        fill: white;
      }

      p {
        text-align: center;

        color: white;

        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  &__wrapper {
    position: relative;

    width: 180px;
    max-width: 180px;
    height: 180px;
    max-height: 180px;

    transition: 0.3s;

    border: 1px dashed #82868a;
    border-radius: 25px;

    &:hover {
      .cover__background {
        transition: 0.3s;

        filter: grayscale(0.6) opacity(0.8) blur(1px);
      }

      .cover__input {
        transition: background 0.3s;

        background: #f4f4f4;

        &--uploaded {
          transition: 0.4s 0.1s;

          opacity: 100%;
          background: none;
        }
      }
    }
  }
}

.preview {
  &__title {
    margin-bottom: 16px;

    text-align: center;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 18px;
  }

  &__data {
    display: flex;
    align-items: center;

    margin-bottom: 16px;

    gap: 23px;
  }

  &__cover {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 175px;
    height: 175px;

    border-radius: 20px;
    background: #f3f3f3;

    img {
      width: 100%;
      height: 100%;

      border-radius: 20px;

      object-fit: cover;
    }

    svg {
      width: 64px;
      height: 64px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__text {
    display: flex;
    flex-direction: column;

    gap: 8px;
  }

  &__info-title {
    color: #82868a;

    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
  }

  &__info-subtitle {
    margin-bottom: 8px;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 100%;
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 120px;
    height: 30px;
    margin-top: 8px;

    cursor: pointer;

    border: none;
    border-radius: 30px;
    background: $red;

    gap: 8px;

    svg {
      width: 8px;
      height: 10px;

      fill: white;
    }

    span {
      color: white;
    }
  }

  &__tracks {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    gap: 18px;
  }

  &__track {
    height: 39px;

    cursor: pointer;

    border-radius: 11px;
    background: #f3f3f3;
  }

  &__submit {
    width: min-content;
    max-width: 300px;
    margin: 0 auto;

    font-weight: 600;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 32px;

    gap: 32px;

    svg {
      width: 43px;
      height: 45px;
    }
    p {
      padding: 16px 24px;

      text-align: center;

      color: #82868a;
      border-radius: 100px;
      background: #f3f3f3;

      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
.track {
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 18px 0 16px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 11px;

    width: var(--loadingProgress);
    height: 4px;

    content: "";

    border-radius: 11px 11px 0px 0px;
    background: lightblue;
  }

  &__background {
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  &__general {
    overflow: hidden;

    max-width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__index {
    margin-right: 24px;

    color: #82868a;

    font-weight: 300;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 300;
  }

  &__artist {
    color: #82868a;

    font-size: 1.125rem;
    font-weight: 300;
  }

  &__duration {
    color: #82868a;

    font-weight: 300;
  }

  &--loading {
    -webkit-animation: gradient-move 2s ease infinite;
    -moz-animation: gradient-move 2s ease infinite;
    animation: gradient-move 2s ease infinite;

    background: linear-gradient(90deg, #f6f6f6, #dddddd);
    background-size: 400% 400%;
  }
}

.artist-modal {
  display: flex;
  flex-direction: column;

  min-width: 400px;

  gap: 24px;
}

.cover-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: 16px;

  &__button {
    width: 150px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px) {
  .main__row {
    flex-direction: column;

    &--center {
      align-items: initial;
    }
  }
  .artists__list {
    grid-template-columns: 1fr;
  }
  .flow {
    margin-left: 0;
  }
  .flow__section {
    justify-content: center;
  }
  .flow__step {
    display: none;
  }
  .upload__notifications {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 930px) {
  .flow__section {
    justify-content: center;
  }
  .flow__step {
    display: none;
  }
  .upload__notifications {
    display: none;
  }
}

@media (min-width: 931px) and (max-width: 1366px) {
  .flow__step {
    display: none;
  }
  .flow__main {
    max-width: none;
  }
}
</style>
