<template>
    <div class="pagination">
        <div class="pagination__spacer" />
        <div class="pagination__controls">
            <button
                class="pagination__arrow pagination__arrow--left"
                @click="setPage(options.page - 1)"
                :disabled="options.page <= 1"
            >
                <svg>
                    <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
                </svg>
            </button>
            <div class="pagination__numbers">
                <button
                    v-for="number in pageNumbers"
                    :key="number"
                    @click="setPage(number)"
                    :class="['pagination__number', { 'pagination__number_selected': options.page === number }]"
                >
                    {{ number }}
                </button>
            </div>
            <button
                class="pagination__arrow"
                @click="setPage(options.page + 1)"
                :disabled="options.page >= meta.pageCount"
            >
                <svg>
                    <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
                </svg>
            </button>
        </div>
        <div class="pagination__take">
            <select
                :value="options.take"
                @change="(event) => setTake(parseInt(event.target.value))"
            >
                <option
                    v-for="option in takeOptions"
                    :key="option"
                    :value="option"
                >
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Object,
        meta: Object,
    },
    data() {
        return {
            maxPageOffset: 2, // Показываем по 2 страницы слева и справа
            takeOptions: [5, 10, 20, 30, 40, 50],
        };
    },
    methods: {
        updateOptions(newOptions) {
            this.$emit('options-change', newOptions);
        },
        setPage(page) {
            if (page >= 1 && page <= this.meta.pageCount) {
                this.updateOptions({ ...this.options, page });
            }
        },
        setTake(take) {
            this.updateOptions({ ...this.options, take });
        },
    },
    computed: {
        pageNumbers() {
            const { pageCount } = this.meta;
            const { page } = this.options;
            const range = this.maxPageOffset;

            // Начальная и конечная страницы
            const startPage = Math.max(1, page - range);
            const endPage = Math.min(pageCount, page + range);

            // Генерация списка страниц
            const numbers = [];
            for (let i = startPage; i <= endPage; i++) {
                numbers.push(i);
            }

            return numbers;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: white;
    border-radius: 16px;
    padding: 0 16px;
    box-shadow: $sections_shadow;
    flex-wrap: wrap;

    &__controls {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__arrow {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0 8px;

        svg {
            height: 13px;
            width: 13px;
        }

        &--left {
            transform: rotate(180deg);
        }
    }

    &__numbers {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__number {
        cursor: pointer;
        padding: 0 8px;
        background: none;
        border: none;
        font-size: 1rem;
        text-align: center;

        &_selected {
            color: $red;
        }
    }

    &__take {
        display: flex;
        align-items: center;

        select {
            cursor: pointer;
            background: none;
            border: none;
            color: $red;
            font-size: 1rem;
            text-align: center;
        }

        &:focus {
            outline: none;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;

        &__numbers {
            gap: 4px;
        }

        &__arrow svg {
            height: 10px;
            width: 10px;
        }
    }
}
</style>
