<template>
  <div
    :class="[
      'payment__method',
      'method',
      selected ? 'method--selected' : '',
    ]"
  >
    <div class="method__icon">
      <div class="method__icon">
        <img :src="require(`@/assets/payments/${method.iconFile}`)" alt="" />
      </div>
    </div>
    <div class="method__name">
      {{ method.name }}
    </div>
  </div>
</template>

<script>
export default {
  props:{
    method: Object,
    selected: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.method {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 59px;
  min-width: 80px;
  padding: 12px 12px 8px 12px;
  border: 1px solid #d1d1d1;
  border-radius: 12px;
  background: white;
  transition: 0.2s;
  cursor: pointer;

  &__icon {
    max-height: 13px;

    svg {
      max-height: 13px;
      max-width: 20px;
    }

    img {
      max-height: 13px;
      border-radius: 2px;
    }
  }

  &__name {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
  }

  &--selected {
    border-color: $red;
    color: $red;
  }

  &:hover {
    transition: 0.2s;
    background: #fafafa;
  }
}
</style>