<template>
  <div class="cropper">
    <div class="cropper__container">
      <img :src="src" ref="image" class="cropper__image">
    </div>
    <div class="cropper__sidebar">
      <h1 class="cropper__heading">
        {{ $t('cover_preview') }}
      </h1>
      <img :src="destination" class="cropper__preview">
      <SquareButton class="cropper__submit" @click="save" :disabled="error">
        {{ error ?? $t('save') }}
      </SquareButton>
      <!-- <RandomCoverButton /> -->
      <div class="cropper__notifications">
        <TransitionGroup
          name="squeeze-slide"
          mode="out-in"
        >
          <Notification v-for="noti in notifications.slice(0, 1)"
            :key="noti.id"
            :notification="noti"
          />
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import RandomCoverButton from './RandomCoverButton.vue';
import SquareButton from './SquareButton.vue';
import validateCoverImage from '../utilities/validateCoverImage';
import { mapGetters } from 'vuex';
import Notification from './Notification.vue';

export default {
  components: {
    SquareButton,
    RandomCoverButton,
    Notification,
  },
  props: {
    src: String,
  },
  data () {
    return {
      cropper: {},
      destination: {},
      image: {},
      error: null,
    };
  },
  methods: {
    validateCoverImage,

    setPreview () {
      const canvas = this.cropper.getCroppedCanvas();
      this.destination = canvas.toDataURL('image/png');
    },

    dataURItoBlob (dataURI) {
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    save () {
      const base64 = this.destination;
      const binary = this.dataURItoBlob(base64);

      this.$emit('save', { base64, binary });
    },
  },
  mounted () {
    this.image = this.$refs.image;
    this.destination = this.src;
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      movable: false,
      aspectRatio: 1,
      autoCropArea: 1,
      cropend: this.setPreview,
    });
  },
  computed: {
    ...mapGetters(['notifications'])
  },
  watch: {
    destination () {
      this.validateCoverImage(this.destination).then((isImageValid) => {
        if (!isImageValid) {
          this.error = this.$t('image_is_too_small');
          return;
        }

        this.error = null;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/_nullstyle.scss";
@import "@/assets/_shared.scss";

.cropper {
  display: flex;
  gap: 32px;

  &__container {
    max-width: 512px;
    max-height: 512px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  &__preview {
    border-radius: 8px;
    max-width: 300px;
    max-height: 300px;
    aspect-ratio: 1;
    margin-bottom: 8px;
    object-fit: cover;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
  }

  &__heading {
    font-size: 1.5rem;
  }

  &__notifications {
    display: flex;
    flex-direction: column;

    max-width: 300px;
    padding-top: 16px;

    gap: 16px;
  }
}

@media (max-width: 560px) {
  .cropper {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .cropper {
    flex-wrap: wrap;
    gap: 16px;

    &__container {
      max-width: 100%;
      max-height: 100%;
    }

    &__preview {
      max-width: 100%;
      max-height: 100%;
    }

    &__sidebar {
      padding-top: 0;
      flex: 1;
    }
  }
}
</style>
