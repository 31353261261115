<template>
  <table class="table">
    <thead>
      <th class="table__name" :colspan="(headers[0].title ? 1 : 2)" v-if="name">
        {{ name }}
      </th>
      <th class="table__header" v-if="numbering" />
      <th class="table__header"
        :style="`text-align: ${header.headerCellAlign ?? 'center'}`"
        v-for="header in (name ? headers.slice(headers[0].title ? 1 : 2) : headers)"
        :key="header.field"
        @click="header.isSortable ? sortBy(header) : {}"
      >
        <span>{{ header.localizer ? $t(header.localizer) : header.title }}</span>
      </th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="item" class="table__row" @click="rowClickHandler(item)">
        <td class="table__cell table__index" v-if="numbering">
          {{ index + 1 }}
        </td>
        <td
          v-for="header in headers"
          :class="['table__cell', `table__cell--${header.isImage ? 'image' : 'text'}`]"
          :style="`text-align: ${header.itemCellsAlign ?? 'left'}`"
          :key="header.field"
        >
          <div class="table__image" v-if="header.isImage">
            <img :src="fileSrcs[getFieldByNameFromObject(header.field, item)] ?? require('@/assets/placeholder.png')" alt="Cover">
          </div>
          <div class="table__chip" v-else-if="header.isStatusChip">
            <StatusChip
              :status="getFieldByNameFromObject(header.field, item)"
            />
          </div>
          <div class="table__icon" v-else-if="header.isIcon">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + `#${getFieldByNameFromObject(header.field, item)}`}" />
            </svg>
          </div>
          <button class="table__button" v-else-if="header.isButton" @click.stop="getFieldByNameFromObject(header.field, item).callback">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + `#${getFieldByNameFromObject(header.field, item).icon}`}" />
            </svg>
          </button>
          <div
            v-else
            :class="['table__text', header.copyField ? 'table__text--copyable' : '']"
            :title="header.copyField ? `Click to copy data` : ''"
            @click="(event) => copyCell(header, item, event)"
          >
            <span>
              {{ getFieldByNameFromObject(header.field, item) }}
            </span>
            <span
              v-if="header.subtitleField"
              class="table__subtitle"
            >
              {{ getFieldByNameFromObject(header.subtitleField, item) }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusChip from './StatusChip';
import { mapActions, mapGetters } from 'vuex';
import copyToClipboard from '@/utilities/copyToClipboard.js';

export default {
  emits: ['rowClick'],
  props: {
    name: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    numbering: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    StatusChip,
  },
  data () {
    return {
      fileSrcs: [],
    };
  },
  mounted () {
    this.downloadImages();
  },
  methods: {
    ...mapActions(['fetchFile']),
    copyToClipboard,

    navigateTo (url) {
      if (url && url.length) {
        this.$router.replace(url);
      }
    },

    rowClickHandler (item) {
      this.navigateTo(item.redirectUrl);
      this.$emit('rowClick', item);
    },

    /* example of usage
      const obj = {
        foo: {
          bar: "baz"
        }
      }

      const fieldName = "foo.bar";
      const fieldValue = getFieldByNameFromObject(fieldName, obj);

      console.log(fieldValue); // logs "baz"
    */
    getFieldByNameFromObject (fullFieldName, object) {
      const fieldNames = fullFieldName.split('.');

      let value = object[fieldNames[0]];

      if (fieldNames.length > 1) {
        for (let i = 1; i < fieldNames.length; i++) {
          value = value[fieldNames[i]];
        }
      }

      return value;
    },

    sortBy (header) {
      console.log(header);
    },

    copyCell (header, item, event) {
      if (!header.copyField) return;

      event.stopPropagation(); // to prevent redirect (navigateTo)

      const cellData = this.getFieldByNameFromObject(header.copyField, item);
      const notificationName = 'Data';

      copyToClipboard(cellData, notificationName);
    },

    getAllImageUuids () {
      const imageHeaders = this.headers.filter((header) => header.isImage);
      if (imageHeaders.length < 1) {
        return;
      }

      const imageHeaderFields = imageHeaders.map((header) => header.field);

      const imageUuids = new Set();
      this.items.forEach((item) => {
        imageHeaderFields.forEach((headerField) => {
          const imageUuid = this.getFieldByNameFromObject(headerField, item);
          imageUuids.add(imageUuid);
        });
      });

      return imageUuids;
    },

    downloadImages () {
      const imageUuids = this.getAllImageUuids();
      if (!imageUuids) return;
      imageUuids.forEach((uuid) => {
        this.fetchFile(uuid).then((response) => {
          this.fileSrcs[uuid] = window.URL.createObjectURL(response.data);
        });
      });
    },
  },
  computed: {
    ...mapGetters(['file', 'fileSrc']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.table {
  width: 100%;

  border-spacing: 0 8px;

  border-collapse: separate;

  &__name {
    text-align: start;
    white-space: nowrap;

    font-size: 1.188rem;
    font-weight: 400;
  }

  &__header {
    padding: 0 16px;

    color: #CECECE;

    font-size: 1.188rem;
    font-weight: 400;

    gap: 8px;

    &:last-child {
      padding-right: 32px;
    }
  }

  &__row {
    cursor: pointer;
    transition: .2s;

    &:hover {
      transition: .3s;

      background: $light-gray;
    }
  }

  &__index {
    color: $dark-gray;
  }

  &__cell {
    max-width: 190px;
    padding: 16px 0px;

    word-wrap: break-word;

    font-size: 1.125rem;

    span {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;

      text-overflow: ellipsis;

      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
    }

    &:first-child {
      padding-left: 16px;

      border-radius: 16px 0 0 16px ;
    }

    &:last-child {
      padding-right: 16px;

      border-radius: 0 16px 16px 0 ;
    }

    &--image {
      width: 36px;
      padding-right: 19px;
    }
  }

  &__text {
    &--copyable {
      cursor: copy;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: $dark-gray;
    white-space: nowrap;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
      width: 16px;
      height: 14px;
      fill: #A8A8A8;
    }
  }

  &__chip {
    display: flex;
    justify-content: center;
    padding: 0 4px;
  }

  &__image {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;

    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;

    img{
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__button {
    width: 30px;
    height: 30px;
    //display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 8px;
    transition: .2s;

    &:hover {
      transition: .2s;
      background: #ececec;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #BBBBBB;
    }

    &:hover svg {
      fill: white;
    }
  }
}

@media (max-width: 660px) {
  .table__cell {
    padding: 12px 4px;

    &--image {
      padding-right: 12px;
    }

    &:first-child {
      padding-left: 8px;
    }
  }
}
</style>
