<template>
  <div class="music-label-offer-modal" @click="$emit('close')">
    <div class="body" ref="modalBody" @click.stop>
      <button class="close-btn" @click="$emit('close')">
        <svg class="logo">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#close'}" />
        </svg>
      </button>
      <div class="img">
        <img src="@/assets/MusicLabelOffer.png" alt="" style="user-select: none;">
        <svg class="logo">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#whitelogo'}" />
        </svg>
      </div>
      <div class="info">
        <div class="header-wrapper">
          <p class="top-header">Вы можете</p>
          <h3 class="header">Стать Музыкальным Лейблом</h3>
        </div>
        <div class="list">
          <div class="item">
            <div class="decor" style="left: -10px; top: 0px; transform: rotate(0deg);">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#rec'}" />
              </svg>
            </div>
            <div class="icon-wrapper">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#copyright'}" />
              </svg>
            </div>
            <div class="info-offer">Публикация на площадках от имени вашего лейбла</div>
          </div>
          <div class="item">
            <div class="decor" style="left: -10px; top: 0px; transform: rotate(0deg);">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#triangle'}" />
              </svg>
            </div>
            <div class="icon-wrapper">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#bitcoin'}" />
              </svg>
            </div>
            <div class="info-offer">Индивидуальные варианты вывода средств</div>
          </div>
          <div class="item">
            <div class="decor" style="left: -10px; top: -10px; transform: rotate(30deg);">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#circle'}" />
              </svg>
            </div>
            <div class="icon-wrapper">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#dollar'}" />
              </svg>
            </div>
            <div class="info-offer">100% доход от стримингов</div>
          </div>
          <div class="item">
            <div class="decor" style="left: -10px; bottom: -10px; transform: rotate(30deg);">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#sun'}" />
              </svg>
            </div>
            <div class="icon-wrapper">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#clock'}" />
              </svg>
            </div>
            <div class="info-offer">Публикация релиза вне очереди (от 4 дней)</div>
          </div>
          <div class="item">
            <div class="decor" style="left: -10px; bottom: -10px; transform: rotate(30deg);">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#star-round'}" />
              </svg>
            </div>
            <div class="icon-wrapper">
              <svg class="logo">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#star-round'}" />
              </svg>
            </div>
            <div class="info-offer">Неограниченное количество артистов</div>
          </div>
        </div>
        <p v-if="isAnswered">Ваша заявка на рассмотрении. Спасибо!</p>
        <Button v-else @click="applyOffer">Оставить заявку</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/RedButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data(){
    return{
      isAnswered: false
    }
  },
  components: {
    Button
  },
  mounted() {
    // if(!this.surveyBySlug('MLO1')){
    //   this.fetchSurveyBySlug('MLO1')
    //   .then(response => {
    //     this.fetchMySurveyAnswers(response.data).then(response=>{
    //       if(response.data.length)
    //         this.isAnswered = true
    //     })
    //   })
    //   .catch(() => {
    //     this.updateMusicLabelOffer(false)
    //   })
    // }
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    ...mapActions(['createAnswer', 'fetchSurveyBySlug', 'fetchMySurveyAnswers']),
    ...mapMutations(['updateMusicLabelOffer']),
    applyOffer(){
      // const survey = this.surveyBySlug('MLO1')

      this.createAnswer({option: survey.questions[0].options[0], valueBool: true}).then(() =>{
        this.isAnswered = true
      })
    }
  },
  computed:{
    ...mapGetters(['surveyBySlug','getSurveyAnswers'])
  }
}
</script>

<style lang="scss" scoped>
/* Ваши стили здесь */
.music-label-offer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(#fff, 0.5);
  backdrop-filter: blur(20px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
  height: 730px;
  width: 1080px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  background: white;
  box-sizing: border-box;
  display: flex;
  position: relative;
}
.close-btn {
  border: none;
  background: transparent;
  right: 10px;
  top: 10px;
  position: absolute;
  z-index: 10;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: 0.2s;
}
.close-btn:hover {
  transform: translateY(-2px);
}
.close-btn:active {
  transform: translateY(-1px);
}
.close-btn svg {
  width: 100%;
  height: 100%;
  fill: black;
}
.img {
  height: 100%;
  width: 400px;
  position: relative;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img .logo {
  position: absolute;
  bottom: 45px;
  left: 45px;
  width: 32px;
  height: 32px;
  z-index: 1;
}
.img .logo svg {
  width: 32px;
  height: 32px;
}
.info {
  flex-grow: 1;
  padding: 60px;
  box-sizing: border-box;
}
.header-wrapper .top-header {
  padding: 0;
  margin: 0 0 20px 0;
}
.header-wrapper .header {
  font-size: 64px;
  width: 460px;
  margin: 0;
  padding: 0;
}
.list {
  padding: 33px 0;
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
  cursor: default;

  &:hover{
    &:nth-child(1n){
      .icon-wrapper{
        background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#fa6d02, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
      }

      .decor{
        svg{
          fill: #fa6d02;
        }
      }
    }
    &:nth-child(2n){
      .icon-wrapper{
        background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#fa9b02, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
      }

      .decor{
        svg{
          fill: #fa9b02;
        }
      }
    }
    &:nth-child(3n){
      .icon-wrapper{
        background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#4cfa02, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
      }

      .decor{
        svg{
          fill: #4cfa02;
        }
      }
    }
    &:nth-child(4n){
      .icon-wrapper{
        background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#00b3ff, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
      }

      .decor{
        svg{
          fill: #00b3ff;
        }
      }
    }
    &:nth-child(5n){
      .icon-wrapper{
        background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#4400ff, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
      }

      .decor{
        svg{
          fill: #4400ff;
        }
      }
    }
  }
}
.icon-wrapper {
  width: 35px;
  height: 35px;
  background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#fa0202, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
  backdrop-filter: blur(3px);
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .2s;
}
.icon-wrapper .logo {
  width: 50%;
  height: 50%;
  fill: white;
}
.decor {
  position: absolute;
}
.decor svg {
  width: 30px;
  height: 30px;
  fill: #fa0202;
}
.info-offer {
  padding-top: 3px;
  margin: 0 0 0 10px;
}


@media screen and (max-width: 750px) {
  .body{
    display: flex;
    flex-direction: column;
    max-width: 450px;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    background: white;
    box-sizing: border-box;
    position: relative;
  }

  .close-btn{
    top: 15px;
    right: 15px;
    svg{
      fill: white
    }
  }

  .img {
    min-height: 200px;
    width: 100%;
    position: relative;
    background: red;
    background-image: url('../assets/MusicLabelOfferMini.png');
    background-size: 100% 100%;
    
    img{
      display: none;
    }
  }

  .img .logo {
    position: absolute;
    bottom: 35px;
    left: 35px;
    width: 32px;
    height: 32px;
    z-index: 1;
  }

  .info {
    flex-grow: 1;
    padding: 35px;
    box-sizing: border-box;
  }

  .header-wrapper .top-header {
    display: none;
  }
  .header-wrapper .header {
    font-size: 40px;
    width: 290px;
    margin: 0;
    padding: 0;
  }

  .icon-wrapper {
    width: 28px;
    height: 28px;
    background: radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(#fa0202, 0.7) 0%, rgb(255, 255, 255, 0.5) 100%);
    backdrop-filter: blur(3px);
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: .2s;
  }

  .info-offer {
    font-weight: 200;
    font-size: 13px;
    max-width: 264px;
    padding-top: 3px;
    margin: 0 0 0 10px;
  }

  // .img img {
  //   transform: rotate(90deg);
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
  // .img .logo {
  //   position: absolute;
  //   bottom: 45px;
  //   left: 45px;
  //   width: 32px;
  //   height: 32px;
  //   z-index: 1;
  // }
  // .img .logo svg {
  //   width: 32px;
  //   height: 32px;
  // }
}

</style>
