<template>
  <div class="noti" :style="cssVariables" @click="isOpened = !isOpened">
    <div class="noti__aside">
      <div class="noti__icon-wrapper">
        <svg class="noti__icon">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#' + style.iconName}" />
        </svg>
      </div>
    </div>
    <div class="noti__main">
      <h3 class="noti__heading" @click="(e) => isOpened && e.stopPropagation()">
        {{ notification.heading }}
      </h3>
      <Transition name="squeeze" mode="out-in">
        <div class="noti__body" v-if="isOpened">
          <p class="noti__message" @click.stop>
            {{ notification.message }}
          </p>
          <div class="noti__actions">
            <button class="noti__button noti__button--dismiss" @click="deleteNotification(notification.id)">
              {{ notification.dismissButtonText ?? $t('hide') }}
            </button>
            <button class="noti__button noti__button--action" @click="notification.actionCallback" v-if="notification.actionCallback">
              {{ notification.actionButtonText ?? $t('more') }}
            </button>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    notification: {
      id: String,
      heading: String,
      message: String,
      style: String,
      lifetime: Number,
      dismissButtonText: String,
      actionButtonText: String,
      actionCallback: Function,
      opened: Boolean,
    },
  },
  data () {
    return {
      isOpened: false,
      lifeTimeout: undefined,

      styles: {
        default: {
          primaryColor: '#71CD69',
          secondaryColor: '#D8FFDC',
          iconName: 'success',
        },
        failure: {
          primaryColor: '#E34242',
          secondaryColor: '#FFC1C1',
          iconName: 'failure',
        },
        warning: {
          primaryColor: '#DDA63A',
          secondaryColor: '#FFEFD8',
          iconName: 'warning',
        },
        success: {
          primaryColor: '#71CD69',
          secondaryColor: '#D8FFDC',
          iconName: 'success',
        },
        info: {
          primaryColor: '#BBBBBB',
          secondaryColor: '#F6F6F6',
          iconName: 'info',
        },
      },
    };
  },
  methods: {
    ...mapActions([
      'deleteNotification',
    ]),

    setLifeTimeout () {
      const lifetime = this.notification.lifetime;

      if (lifetime && lifetime > 0) {
        this.lifeTimeout = setTimeout(() => {
          this.deleteNotification(this.notification.id);
        }, lifetime);
      }
    },

    resetLifeTimeout () {
      clearTimeout(this.lifeTimeout);
      this.setLifeTimeout();
    },

    initIsOpened () {
      const initialIsOpened = this.notification.opened;
      if (initialIsOpened !== null) {
        this.isOpened = initialIsOpened;
      }
    },
  },
  computed: {
    style () {
      const customStyle = this.styles[this.notification.style];
      return customStyle ?? this.styles.default;
    },

    cssVariables () {
      return {
        '--gap': this.isOpened ? '8px' : '0px',
        '--padding': this.isOpened ? '10px' : '20px',
        '--primary-color': this.style.primaryColor,
        '--secondary-color': this.style.secondaryColor,
      };
    },
  },
  mounted () {
    this.setLifeTimeout();
    this.initIsOpened();
  },
  watch: {
    isOpened () {
      this.resetLifeTimeout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.squeeze-leave-active,
.squeeze-enter-active {
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.squeeze-enter-from,
.squeeze-leave-to {
  opacity: 0;
  max-height: 0px;
  transform: scale(.98);
}

.squeeze-enter-to,
.squeeze-leave-from {
  opacity: 1;
  max-height: 300px;
  transform: scale(1);
}

.noti {
  display: flex;
  overflow: hidden;

  min-height: 65px;

  border-radius: $sections_border_radius;
  background: white;
  box-shadow: $sections_shadow;

  &__aside {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    min-width: 64px;

    cursor: pointer;

    border-radius: 20px 0 0 20px;
    background: var(--secondary-color);
    transition: .3s;

    &:hover {
      transition: .3s;
      filter: brightness(1.02);
    }
  }

  // &__icon-wrapper { }

  &__icon {
    width: 36px;
    height: 36px;

    fill: var(--primary-color);
  }

  &__main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    padding: 20px 10px var(--padding) 16px;
    gap: var(--gap);

    transition: .3s;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 100%;
  }

  &__body {
    display: flex;
    flex: 1;
    flex-direction: column;

    gap: 10px;
  }

  &__spacer {
    margin-bottom: 4px;
  }

  &__message {
      color: #82868A;
      font-size: 1rem;
      font-weight: 400;
      max-width: 500px;
      word-wrap: break-all;
      word-break: break-word;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    gap: 8px;
  }

  &__button {
    cursor: pointer;
    user-select: none;

    border: none;

    font-size: 1rem;
    font-weight: 600;
    line-height: 16px;

    &--dismiss {
      color: #B1B1B1;
      background: none;
      padding: 0 8px;
    }

    &--action {
      height: 36px;

      min-width: 110px;
      padding: 0 22px;

      color: var(--primary-color);
      border-radius: 12px;
      background: var(--secondary-color);
      box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
    }
  }
}

</style>
