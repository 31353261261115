<template>
    <div class="drafts-loading" v-if="isDataLoading">
        <LoadingLogo />
    </div>
    <section class="drafts" v-else>
        <div class="drafts__main">
            <Table
                v-if="draftsReleasesList.length"
                :name="$t('drafts')"
                :headers="tableHeaders"
                :items="draftsReleasesList"
            />
            <DataPlaceholder
                v-else
                :title="$t('no_drafts_placeholder_title')"
                :description="$t('no_drafts_placeholder_description')"
                :button-text="$t('no_drafts_placeholder_action')"
                :button-redirect-url="'/upload'"
            />
        </div>
        <Pagination
            v-if="draftsReleasesList?.length"
            :options="pagination.options"
            :meta="pagination.meta"
            @options-change="(newOptions) => pagination.options = newOptions"
        />
        <Modal
            v-if="selectedDraft"
            :visibility="showModal"
            @switch="closeModal"
            :heading="$t('delete_confirm')"
            :description="$t('delete_confirm_description')"
        >
            <div class="modal-content">
                <div v-if="isModalLoading" class="modal-skeleton">
                    <div class="form__main">
                        <div class="preview__data">
                            <div class="preview__cover">
                                <div class="skeleton-cover"></div>
                            </div>
                            <div class="preview__info">
                                <div class="skeleton-text"></div>
                                <div class="skeleton-text"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form__actions">
                        <div class="skeleton-button"></div>
                        <div class="skeleton-button"></div>
                    </div>
                </div>



                <div v-else class="form">
                    <div class="form__main">
                        <div class="preview__data">
                            <div class="preview__cover">
                                <img
                                    :src="selectedDraft.artist_cover || placeholderCover"
                                    alt="Artist Cover"
                                    class="draft-cover"
                                />
                            </div>
                            <div class="preview__info">
                                <div class="preview__text">
                                    <p><b>{{ selectedDraft.title }}</b></p>
                                    <p>{{ selectedDraft.artist?.name || 'Artist' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form__actions">
                        <RedButton @click="deleteDraft">{{ $t('delete') }}</RedButton>
                        <button
                            @click="closeModal"
                            class="form__action form__action--cancel"
                        >
                            {{ $t('cancel') }}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import LoadingLogo from '@/components/LoadingLogo.vue';
import Table from '@/components/Table.vue';
import DataPlaceholder from '@/components/DataPlaceholder.vue';
import Modal from '@/components/Modal.vue';
import RedButton from '@/components/RedButton.vue';
import { name as tableName, headers as tableHeaders } from '@/utilities/tableStructures/drafts.js';
export default {
    components: {
        Table,
        Pagination,
        LoadingLogo,
        DataPlaceholder,
        Modal,
        RedButton,
    },
    data() {
        return {
            isDataLoading: true,
            isModalLoading: false, // Новый флаг для загрузки данных в модальном окне
            tableName,
            tableHeaders,
            selectedDraft: null,
            showModal: false,
            pagination: {
                options: {
                    page: 1,
                    take: 10,
                    status: 'DRAFT',
                },
                meta: {},
            },
            placeholderCover: require("@/assets/placeholder.png"),
        };
    },
    mounted() {
        this.fetchUserArtists();
    },
    computed: {
        ...mapGetters(['draftReleases', 'artistByUuid']),

        draftsReleasesList() {
            return this.draftReleases.map((release) => {
                return {
                    ...release,
                    release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
                    artist: {
                        name:  'Artist',
                    },
                    redirectUrl: `/drafts/${release.uuid}`,
                    delete_button: {
                        callback: () => this.openDeleteModal(release),
                        icon: 'delete',
                    },
                };
            });
        },
    },
    methods: {
        ...mapActions(['fetchFile', 'fetchAllReleases', 'fetchUserArtists', 'deleteDraftByUuid', 'fetchArtist', 'fetchReleaseWithoutAdd', 'getFileCdnLink']),

        async fetchReleasesRequest() {
            try {
                const response = await this.fetchAllReleases(this.pagination.options);
                this.pagination.meta = response.data.meta;
            } finally {
                this.isDataLoading = false;
            }
        },

        async prepareArtist(artistUuid) {
            const preparedArtist = (await this.fetchArtist(artistUuid)).data;

            if (preparedArtist.avatar_uuid) {
                const artistAvatar = (await this.getFileCdnLink(preparedArtist.avatar_uuid)).data;
                preparedArtist.avatarCdnLink = artistAvatar;
            }

            return preparedArtist;
        },

        async openDeleteModal(draft) {
            this.showModal = true;
            this.isModalLoading = true;
            this.selectedDraft = {
                ...draft,
                artist: null,
                artist_cover: this.placeholderCover,
            };

            try {
                const release = (await this.fetchReleaseWithoutAdd(draft.uuid)).data;
                if (release.artists?.length) {
                    const artistData = release.artists[0];
                    const preparedArtist = await this.prepareArtist(artistData.artist_uuid);
                    this.selectedDraft.artist = preparedArtist;
                    this.selectedDraft.artist_cover = preparedArtist?.avatarCdnLink || this.placeholderCover;
                }

                if (draft.cover_uuid) {
                    const response = await this.fetchFile(draft.cover_uuid);
                    const coverUrl = window.URL.createObjectURL(response.data);
                    this.selectedDraft.artist_cover = coverUrl;
                }
            } catch (error) {
                console.error('Ошибка загрузки данных для модального окна:', error);
            } finally {
                this.isModalLoading = false;
            }
        },

        closeModal() {
            this.showModal = false;
            this.selectedDraft = null;
        },

        async deleteDraft() {
            if (!this.selectedDraft) return;
            const { uuid } = this.selectedDraft;

            try {
                await this.deleteDraftByUuid(uuid);
                this.$notify.success(this.$t('delete_success'));
                this.fetchReleasesRequest();
            } catch {
                this.$notify.error(this.$t('delete_error'));
            } finally {
                this.closeModal();
            }
        },
    },
    watch: {
        'pagination.options': {
            deep: true,
            immediate: true,
            handler() {
                this.fetchReleasesRequest();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

@mixin gradient-animation {
    -webkit-animation: gradient-move 3s ease infinite;
    -moz-animation: gradient-move 3s ease infinite;
    animation: gradient-move 3s ease infinite;
    background: linear-gradient(45deg, #c7c7c7, #e9e9e9);
    background-size: 300% 300%;
}

.drafts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__main {
        @include section;
        overflow-x: auto;
    }
}

.drafts-loading {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    width: 100%;
}

.modal-skeleton {
    display: flex;
    flex-direction: column;
    min-width: 400px;

    .form__main {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .preview__data {
            display: flex;
            gap: 20px;

            .preview__cover {
                width: 67px;
                height: 67px;
                border-radius: 20px;
                background: #f3f3f3;
                @include gradient-animation;
            }

            .preview__info {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15px;

                .skeleton-text {
                    width: 70%;
                    height: 15px;
                    border-radius: 10px;
                    @include gradient-animation;
                }
            }
        }
    }

    .form__actions {
        display: flex;
        justify-content: space-between;

        .skeleton-button {
            width: 120px;
            height: 44px;
            border-radius: 40px;
            @include gradient-animation;
        }
    }
}

@keyframes gradient-move {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.preview {
    &__data {
        display: flex;
        align-items: center;
        gap: 23px;
    }

    &__text {
        p {
            margin: 0;
            line-height: 1.5;
        }
    }

    &__cover {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 67px;
        height: 67px;
        border-radius: 20px;
        background: #F3F3F3;

        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 400px;

    &__main {
        width: 100%;
        margin-bottom: 32px;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }

    &__action {
        height: 36px;
        font-size: 1rem;
        font-weight: 500;

        &--cancel {
            background: none;
            border: none;
            color: $red;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                transform: scale(1.04);
            }
        }
    }
}
</style>