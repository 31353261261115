<template>
  <div class="payment">
    <div class="payment__proccess" v-if="!isPaymentStarted">
      <div class="payment__main">
        <div class="payment__form">
          <div class="payment__block">
            <p class="payment__title">Choose method:</p>
            <div class="payment__methods">
              <PaymentTypeIcon
                @click="selectPaymentMethod(method)"
                v-for="method in activePaymentMethods"
                :key="method.key"
                :method="method"
                :selected="selectedPaymentMethodKey == method.key"
              />
            </div>
          </div>
          <div
            class="payment__block"
            v-if="isSelectedMethod(PaymentMethods.card)"
          >
            <p class="payment__title">Card details</p>
            <div class="payment__card">
              <div class="payment__card-row">
                <InputWithLabel
                  class="payment__input"
                  v-model="payment.number"
                  v-mask="'#### #### #### #### ###'"
                  placeholder="0000 0000 0000 0000 000"
                  :validations="validations.payment.number"
                  :show-errors="false"
                />
                <InputWithLabel
                  class="payment__input payment__input--small"
                  v-model="payment.cvv"
                  v-mask="'####'"
                  placeholder="CVV"
                  :validations="validations.payment.cvv"
                  :show-errors="false"
                />
              </div>
              <div class="payment__card-row">
                <InputWithLabel
                  class="payment__input"
                  v-model="payment.cardholder"
                  placeholder="Cardholder name"
                  :validations="validations.payment.cardholder"
                  :show-errors="false"
                />
                <InputWithLabel
                  class="payment__input payment__input--small"
                  v-model="payment.expiry"
                  v-mask="'## / ##'"
                  placeholder="MM / YY"
                  :validations="validations.payment.expiry"
                  :show-errors="false"
                />
              </div>
            </div>
          </div>
          <div
            class="payment__block"
            v-if="isSelectedMethod(PaymentMethods.card)"
          >
            <p class="payment__title">Email address</p>
            <InputWithLabel
              class="payment__input"
              type="email"
              v-model="payment.email"
              placeholder="Email address"
              :validations="validations.payment.email"
            />
          </div>
          <RedButton
            v-if="!loading"
            class="payment__submit"
            @click="paymentRequest"
            :disabled="v$.$silentErrors.length > 0"
          >
            Pay ${{ total }}
          </RedButton>
          <RedButton v-if="loading">
            Processing
            <template v-slot:pre-icon>
              <SvgIcon type="mdi" :path="icons.loading" class="loading-anim" />
            </template>
          </RedButton>
          <InfoSubtitle>
            Конечная сумма может отличаться в зависимости от платёжной системы.
            Если у вас остались вопросы, пожалуйста, обратитесь в поддержку:
            <a href="mailto:mail@mylabel.cc">mail@mylabel.cc</a>
          </InfoSubtitle>
        </div>
        <div class="payment__order">
          <p class="payment__title">Your Order</p>
          <div class="payment__products">
            <div
              v-for="purchase in cartPurchases"
              :key="purchase.name"
              class="purchase-wrapper"
            >
              <ProductCard
                v-if="purchase.type == PurchaseType.PRODUCT"
                :product="purchase.product"
              />
              <SubscriptionCard
                v-else-if="purchase.type == PurchaseType.SUBSCRIPTION"
                :subscription="purchase.subscription"
              />
            </div>
          </div>
          <div class="payment__total total">
            <p class="total__title">Total</p>
            <p class="total__amount">${{ total }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="payment__succeed" v-else>
      <Label v-if="!paymentSuccessful" warning class="label"
        >Платёж обрабатывается, не закрывайте окно</Label
      >
      <Label v-else class="label">Платёж успешно обработан!</Label>
      <div
        class="order-wrapper"
        v-for="order in cartPurchases"
        :key="order.uuid"
      >
        <Order :uuid="order.uuid" :purchaseType="order.type" />
      </div>
      <div class="price-wrapper">
        <div class="title-wrapper">
          <div class="title">Стоимость</div>
          <div class="subtitle">
            {{ selectedServiceSentence }}
          </div>
        </div>
        <div class="price">{{ total }}$</div>
      </div>
      <InfoSubtitle>
        Конечная сумма может отличаться в зависимости от платёжной системы. Если
        у вас остались вопросы, пожалуйста, обратитесь в поддержку:
        <a href="mailto:mail@mylabel.cc">mail@mylabel.cc</a>
      </InfoSubtitle>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { mdiLoading } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { PurchaseType } from "@/utilities/purchasesType";
import { PaymentMethods } from "@/utilities/PaymentMethods";
import openUrlInNewTab from "@/utilities/openUrlInNewTab";
import RedButton from "./RedButton.vue";
import InputWithLabel from "./InputWithLabel.vue";
import ProductCard from "./payments/ProductCard.vue";
import Order from "./Order.vue";
import SubscriptionCard from "./payments/SubscriptionCard.vue";
import PaymentTypeIcon from "./payments/PaymentTypeIcon.vue";
import Label from "./payments/Label.vue";
import InfoSubtitle from "./payments/InfoSubtitle.vue";

export default {
  components: {
    SvgIcon,
    RedButton,
    InputWithLabel,
    ProductCard,
    Order,
    PaymentTypeIcon,
    Label,
    SubscriptionCard,
    InfoSubtitle,
  },
  data() {
    return {
      PaymentMethods,
      PurchaseType,
      v$: useValidate(),

      payment: {
        number: "",
        cvv: "",
        cardholder: "",
        expiry: "",
        email: "",
      },
      icons: {
        loading: mdiLoading,
      },
      selectedPaymentMethodKey: "paypal",
      isPaymentStarted: false,
      loading: false,
    };
  },
  mounted() {
    this.fetchProducts();
    this.fetchSubscriptions();
  },
  watch: {
    paymentSuccessful() {
      if (this.paymentSuccessful) this.$emit("payment");
    },
  },
  methods: {
    ...mapActions([
      "checkoutUserProducts",
      "checkoutUserSubscription",
      "setIsPaymentModal",
      "fetchProducts",
      "fetchSubscriptions",
    ]),
    openUrlInNewTab,

    selectPaymentMethod(methodOrKey) {
      const key = methodOrKey.key ?? methodOrKey;
      this.selectedPaymentMethodKey = key;
    },

    isSelectedMethod(methodOrKey) {
      const key = methodOrKey.key ?? methodOrKey;
      return key == this.selectedPaymentMethodKey;
    },

    checkoutPurchaseRequest() {
      const { paymentType } =
        this.PaymentMethods[this.selectedPaymentMethodKey];
      const payload = { paymentType };
      const purchase = this.cartPurchases[0];

      const userProductUuids = this.cartPurchases.map(
        (userProducts) => userProducts.uuid
      );

      switch (purchase.type) {
        case PurchaseType.SUBSCRIPTION:
          payload.userSubscriptionUuid = purchase.uuid;
          return this.checkoutUserSubscription(payload);
        case PurchaseType.PRODUCT:
          payload.userProductUuids = userProductUuids;
          return this.checkoutUserProducts(payload);
      }
    },

    paymentRequest() {
      this.loading = true;
      this.checkoutPurchaseRequest()
        .then((response) => {
          const { payUrl } = response.data;
          if (payUrl && payUrl.length) {
            this.openUrlInNewTab(payUrl);
            this.isPaymentStarted = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["cartPurchases", "products", "purchase"]),

    activePaymentMethods() {
      return Object.values(this.PaymentMethods).filter((m) => m.isActive);
    },

    paymentSuccessful() {
      return !Boolean(
        this.cartPurchases.find((purchase) => purchase.status != "PAID")
      );
    },

    total() {
      console.log(this.cartPurchases);
      if (this.cartPurchases.length)
        return this.cartPurchases.reduce(
          (total, purchase) =>
            total +
            (purchase.type == PurchaseType.SUBSCRIPTION
              ? parseInt(purchase.subscription.amount)
              : parseInt(purchase.product.amount)),
          0
        );
      else return 0;
    },

    selectedServiceSentence() {
      const pluralRules = new Intl.PluralRules("ru-RU");
      const rule = pluralRules.select(this.cartPurchases.length);

      const product = {
        one: "услугу",
        few: "услуги",
        many: "услуг",
      };

      const subscription = {
        one: "план",
        few: "плана",
        many: "планов",
      };

      const purchaseType = this.cartPurchases[0]?.type;
      const productWord = product[rule] || "услуг";
      const subscriptionWorld = subscription[rule] || "план";
      const word =
        purchaseType == PurchaseType.PRODUCT ? productWord : subscriptionWorld;
      return `Вы выбрали ${this.cartPurchases.length} ${word}`;
    },

    validations() {
      return {
        payment: {
          number: {
            required,
            minLength: minLength(18), // #### #### #### ###
            maxLength: maxLength(23), // #### #### #### #### ###
          },
          cvv: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(4),
          },
          cardholder: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(40),
          },
          expiry: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(7),
          },
          email: {
            required,
            email,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.payment {
  padding: 16px;

  &__proccess {
  }

  &__succeed {
    width: 550px;

    .order-wrapper {
      margin-bottom: 10px;
    }

    .label {
      margin-bottom: 20px;
    }
  }

  &__main {
    display: flex;
  }

  &__form {
    width: 384px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    padding-right: 24px;
    border-right: 1px solid #e6e6e6;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 6px;
    padding-left: 12px;
  }

  &__methods {
    display: flex;
    gap: 13px;
    margin-bottom: 8px;
  }

  &__method {
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__card-row {
    display: flex;
    gap: 8px;
  }

  &__input {
    width: 100%;

    &--small {
      max-width: 112px;
    }
  }

  &__submit {
    // margin-top: auto;
    height: 44px;
    border-radius: 16px;
    font-size: 1rem;
    font-weight: 600;
  }

  &__order {
    padding-left: 24px;
    width: 380px;
    display: flex;
    flex-direction: column;
  }

  &__products {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 280px;
    height: 100%;
    padding-bottom: 16px;
    overflow-y: auto;
  }

  &__total {
    min-height: 44px;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  margin-top: auto;

  &__title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 16px;
  }

  &__amount {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
  }
}

@media (max-width: 768px) {
  .payment {
    padding: 0;
    margin-top: 16px;

    &__main {
      flex-direction: column;
    }

    &__form {
      width: 100%;
      padding: 0;
      border-right: none;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 16px;
    }

    &__order {
      width: 100%;
      padding: 0;
      padding-top: 16px;
    }
  }
}

.price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;

  .title {
    font-weight: 500;
    font-size: 16px;
  }

  .price {
    font-size: 31px;
    font-weight: 500;
  }
}
</style>
