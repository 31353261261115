import axios from 'axios';
import io from 'socket.io-client';
import { getParsedToken } from '../../utilities/getParsedToken';
import { PurchaseType } from '../../utilities/purchasesType';

const initialState = () => {
  return {
    isPaymentModal: false,
    cartPurchases: [],

    userProducts: [],
    purchases: [],
    productAccesses: [],

    subscriptions: [],
    products: [],

    socket: null
  };
};

const payments = {
  state: () => initialState(),
  actions: {
    fetchUserProductRelease({commit, dispatch},userProductUuid){
      return new Promise((resolve, reject) => {
        axios.get(`/me/user-product/${userProductUuid}/release`).then(response => {

          const release = response.data

          dispatch('releases/fetchRelease', release.uuid)
          
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    setupSocket({ state }) {
      return new Promise((resolve, reject) => {

        const socket = state.socket


        if (socket)
          resolve(socket)
        else {
          const socket = io(`${process.env.VUE_APP_API_URL}`, {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 10,
            reconnectionDelay: 5000,
          });

          socket.on('connect', () => {
            resolve(socket);
          });

          socket.on('connect_error', (error) => {
            state.socket = null
            reject(error);
          });

          socket.on('reconnect_failed', () => {
            reject(new Error('Failed to reconnect after maximum attempts'));
          });

          state.socket = socket
        }
      });
    },

    async observeUserPurchases({ commit, dispatch }, userPurchases) {
      const socket =  await dispatch('setupSocket')
      /*
      userPurchases example
      [
        {
        "typeOrder": "PRODUCT",
        "orderUuid": "4861230e-4eac-4383-a980-508018c873f6"
        }
      ]
      */
      const message = userPurchases.map(purchase => ({
        typeOrder: purchase.type,
        orderUuid: purchase.uuid,
      })) 
      socket.emit('check-payment', message);

      socket.on('data', (data) => {
        commit('updateUserPurchase',data)
      });

      socket.on('error', (data) => {
        //TODO:
      })
    },

    fetchReleaseNeededProducts: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/release/${releaseUuid}/cost`).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showPaymentModal: function ({ commit }) {
      commit('setIsPaymentModal', true);
    },

    hidePaymentModal: function ({ commit }) {
      commit('setIsPaymentModal', false);
    },

    togglePaymentModal: function ({ state, commit }) {
      const value = !state.isPaymentModal;
      commit('setIsPaymentModal', value);
    },

    setIsPaymentModal: function ({ state, commit }, value) {
      commit('setIsPaymentModal', value);
    },

    fetchUserProduct: function ({ commit }, userProductUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/user-product/${userProductUuid}`)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },

    fetchPurchases: function ({ commit }) {
      // todo: let user ability to change date range
      const today = new Date();

      const startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 3);

      const endDate = new Date(today);
      endDate.setFullYear(today.getFullYear() + 3);

      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        order: 'DESC',
      };

      return new Promise((resolve, reject) => {
        axios.get('/me/purchases', { params })
          .then(response => {
            commit('setPurchases', response.data.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },

    fetchUserProductAccess({ commit }, productName) {
      return new Promise((resolve, reject) => {
        axios.get(`/check/${productName}`).then(response => {
          if (response.data?.status == 'PAID') {
            commit('addProductAccess', productName);
          }
          resolve(response);
        }).catch(e => reject(e));
      });
    },

    createUserSubscription: function ({ commit }, subscriptionName) {
      const userUuid = getParsedToken().sub;
      const payload = {
        subscriptionName,
        userUuid,
      };

      return new Promise((resolve, reject) => {
        axios.post('/order/user-subscription', payload).then(response => {
          console.log('00---------',PurchaseType)
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          // commit('addUserProduct', userPurchase);
          commit('addPurchaseToCart', userPurchase);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkoutUserSubscription: function ({ commit }, { userSubscriptionUuid, paymentType }) {
      return new Promise((resolve, reject) => {
        axios.get(`user-subscription/${userSubscriptionUuid}/checkout`, {
          params: {
            paymentType,
          },
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchUserProducts: function ({ commit }) {
      const userUuid = getParsedToken().sub;

      return new Promise((resolve, reject) => {
        axios.get(`/user-product/user/${userUuid}`)
          .then(response => {
            commit('setUserProducts', response.data);
            resolve(response);
          }).catch(error => {
            reject(error); 
          });
      });
    },
    createUserProduct: function ({ commit }, productName) {
      const userUuid = getParsedToken().sub;
      const payload = {
        productName,
        userUuid,
      };

      return new Promise((resolve, reject) => {
        axios.post('user-product', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('addUserProduct', userPurchase);
          // commit('setCartPurchases', [userPurchase]);
          commit('addPurchaseToCart', userPurchase)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkoutUserProduct: function ({ commit }, { userProductUuid, paymentType }) {
      return new Promise((resolve, reject) => {
        axios.get(`user-product/${userProductUuid}/checkout`, {
          params: {
            paymentType,
          },
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkoutUserProducts({ commit }, { userProductUuids, paymentType }) {
      return new Promise((resolve, reject) => {
        axios.post(`user-products/checkout`, {
          user_products_uuids: userProductUuids
        }, {
          params: {
            paymentType,
          }
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    fetchProducts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/products').then(response => {
          const products = response.data.map(product => ({ ...product, amount: parseInt(product.amount) }))
          commit('setProducts', products);
          resolve(products);
        }).catch(e => reject(e));
      });
    },
    fetchSubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/subscriptions').then(response => {
          commit('setSubscriptions', response.data);
          resolve(response);
        }).catch(e => reject(e));
      });
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setProducts(state, products) {
      state.products = products;
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },

    addProductAccess: function (state, productName) {
      state.productAccesses.push(productName);
    },

    setIsPaymentModal: function (state, value) {
      state.isPaymentModal = value;
    },

    setPurchases: function (state, purchases) {
      state.purchases = state.purchases.concat(purchases);
    },
    updateUserPurchase: function(state, purchase){
      console.log('this.updateUserPurchase')
      const statePurchase = state.purchases.find(statePurchase => statePurchase.uuid == purchase.uuid)
      const userPurchase = state.cartPurchases.find(stateUserPurchase => stateUserPurchase.uuid == purchase.uuid)
      if(!statePurchase)
        state.purchases.push(purchase)
      else{
        statePurchase.uuid = purchase.uuid
        statePurchase.paymentType = purchase.paymentType
        statePurchase.status = purchase.status
        statePurchase.product = purchase.product

        userPurchase.uuid = purchase.uuid
        userPurchase.paymentType = purchase.paymentType
        userPurchase.status = purchase.status
        userPurchase.product = purchase.product
      }
    },
    setUserProducts: function (state, userProducts) {
      state.userProducts = userProducts;
    },
    addUserProduct: function (state, userProduct) {
      state.userProducts.push(userProduct);
    },
    removeUserProduct: function (state, uuid) {
      state.userProducts = state.userProducts.filter(p => p.uuid != uuid);
    },

    setCartPurchases: function (state, userProducts) {
      state.cartPurchases = userProducts;
    },
    addPurchaseToCart: function (state, purchase) {
      state.cartPurchases.push(purchase);
    },
    removeUserProductFromCart: function (state, uuid) {
      state.cartPurchases = state.cartPurchases.filter(p => p.uuid != uuid);
    },
  },
  getters: {
    isPaymentModal: state => state.isPaymentModal,
    cartPurchases: state => state.cartPurchases,

    purchases: state => state.purchases,
    purchase: state => uuid => state.purchases.find((p) => p.uuid == uuid),
    purchasesByStatus: state => status => state.purchases.filter((p) => p.status == status),

    userProducts: state => state.userProducts,
    userProduct: state => uuid => state.userProducts.find((p) => p.uuid == uuid),
    userProductsByStatus: state => status => state.userProducts.filter((p) => p.status == status),

    currentUserSubscription: state => state.purchases.find(purchase => purchase.type == PurchaseType.SUBSCRIPTION && purchase.status == 'PAID'),

    productAccesses: state => state.productAccesses,
    productAccess: state => productName => state.productAccesses.find((p) => p == productName),

    products: state => state.products,
    subscriptions: state => state.subscriptions,
  },
};

export default payments;
