<template>
  <section class="app">
    <router-view />
    <!-- LOADER -->
    <Loader />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from './components/Loader.vue';

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapGetters(['isAppLoading']),

    getAppVersion () {
      return process.env.VUE_APP_VERSION;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/_shared.scss';
@import '@/assets/_grid.scss';

*{
  font-family: 'Gilroy';
}

body{
  background: $light-gray;
}

html, body, #app, .app {
  height: 100%;
}

.warning{
  display: flex;
  justify-content: center;
  padding: 10px;
  background: $orange;
  color: white;
  font-weight: 600;
  font-size: 16px;
}
</style>
