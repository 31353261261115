<template>
  <div
    :class="[
      'plan',
      { 'plan--horizontal': data.isHorizontal },
      { 'plan--loading': loading },
    ]"
    :style="
      loading
        ? ''
        : `background: radial-gradient(at top left, ${data.colors.background});`
    "
  >
    <div class="plan__container">
      <div class="plan__header">
        <img :src="data.iconSrc" class="plan__icon" />
        <p class="plan__promo" v-if="data.promo">
          {{ data.promo }}
        </p>
      </div>
      <div class="plan__info">
        <p class="plan__name">
          План
          <span :style="`color: ${data.colors.name};`">{{ data.name }}</span>
        </p>
        <p
          class="plan__duration"
          :style="`background: ${data.colors.duration};`"
        >
          {{ getDuration(data.duration) }}
        </p>
      </div>
      <div class="plan__terms">
        <div class="plan__price" v-if="data.price > 0 && data.duration.period">
          <sup>$</sup>
          <p>
            {{ data.price }}
            <span :style="`color: ${data.colors.subtitle}; font-size: 1.6rem;`"
              >/</span
            >
            <span>{{ periods[data.duration.period][0] }}</span>
          </p>
        </div>
        <div class="plan__price plan__price--free" v-else>
          <p>Бесплатно</p>
        </div>
        <p class="plan__subtitle" :style="`color: ${data.colors.subtitle};`">
          <span>{{ data.subtitle }}</span>
        </p>
      </div>
    </div>
    <div class="plan__hr" :style="`background: ${data.colors.hr};`" />
    <div class="plan__body plan__container">
      <div class="plan__skeleton__title">
        <div class="title"></div>
        <div class="subtitle"></div>
        <div class="button"></div>
      </div>
      <div class="plan__details">
        <div
          class="plan__detail"
          v-for="detail in data.details"
          :key="detail.text"
        >
          <svg
            :style="`fill: ${
              !detail.inactive
                ? data.colors.activeIcon +
                  '; filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2))'
                : data.colors.inactive
            };`"
          >
            <use
              v-bind="{
                'xlink:href':
                  require('@/assets/icons/all_icons.svg') +
                  (!detail.inactive ? '#check' : '#inactive'),
              }"
            />
          </svg>
          <p
            class="plan__detail-text"
            :style="`color: ${
              detail.inactive ? data.colors.inactive : 'white'
            }`"
            v-html="detail.text"
          />
        </div>
      </div>
      <!-- <button
        class="plan__button plan__button--loading"
        :style="`background: ${data.colors.button}`"
      >
        Загрузка
        <SvgIcon :path="icons.loading" type="mdi" />
      </button> -->
      <button
        v-if="!isCurrentPlan"
        @click="$emit('order')"
        class="plan__button"
        :style="`background: ${data.colors.button}`"
      >
        {{ data.buttonText }}
      </button>
      <button
        v-else
        @click="$emit('order')"
        class="plan__button"
        :style="`background: ${data.colors.button}`"
        disabled
      >
        Ваш текущий план
      </button>
    </div>
  </div>
</template>

<script>
import { mdiLoading } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    isCurrentPlan: Boolean
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      periods: {
        HOUR: ["час", "часа", "часа"],
        DAY: ["день", "дня", "дней"],
        MONTH: ["месяц", "месяца", "месяцев"],
        YEAR: ["год", "года", "лет"],
      },
      icons: {
        loading: mdiLoading,
      },
    };
  },
  methods: {
    getPrice(price, duration) {
      return price === 0
        ? "Бесплатно"
        : `${price} / ${this.periods[duration.period][0]}`;
    },

    getDuration(duration) {
      let periodString;
      if (duration.number !== 0) {
        if (duration.number == 1) {
          periodString = this.periods[duration.period][0];
        } else if ([2, 3, 4].includes(duration.number)) {
          periodString = this.periods[duration.period][1];
        } else periodString = this.periods[duration.period][2];

        return `${duration.number} ${periodString}`;
      } else return "Без лимита";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.plan {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 24px;
  border-radius: 24px;
  font-size: 1.188rem;
  color: white;
  height: 100%;

  .plan__skeleton__title {
    display: none;
  }

  &__container {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -32px;
  }

  &__icon {
    width: 72px;
  }

  &__promo {
    background: rgba($color: #1e3658, $alpha: 0.3);
    backdrop-filter: blur(3px) contrast(130%);
    padding: 10px 20px;
    border-radius: 100px;
    box-shadow: 0 0 3px 0 rgba($color: #bbb, $alpha: 0.5);
    font-size: 1rem;
    text-align: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 0.9rem;
    span {
      margin-top: -4px;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  &__duration {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    border-radius: 100px;
    font-size: 1rem;
  }

  &__terms {
    position: relative;
    margin-bottom: 16px;
  }

  &__price {
    position: relative;
    font-size: 1.4rem;
    margin-left: 8px;

    sup {
      position: absolute;
      font-weight: 600;
      font-size: 1rem;
      top: 8px;
      left: -10px;
    }

    span {
      font-size: 1.4rem;
    }

    &--free {
      margin-left: 0;
    }
  }

  &__subtitle {
    font-size: 1rem;
    margin-bottom: 3px;
  }

  &__hr {
    width: 100%;
    height: 2px;
    margin-bottom: 16px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    height: 100%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 0.9rem;
  }

  &__detail {
    display: flex;
    align-items: center;

    svg {
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      margin-right: 12px;
    }
  }

  &__detail-text {
    span {
      font-weight: 700;
    }
  }

  &__action {
  }

  &__button {
    width: 100%;
    padding: 16px 0;
    font-weight: 500;
    border-radius: 100px;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.1s ease;
    margin-top: auto;

    &:active {
      transition: 0.2s ease;
      transform: scale(0.97);
    }

    &:hover {
      transition: 0.3s ease;
      filter: brightness(95%);
    }
  }
  &__button--loading {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: blueviolet;
    svg {
      margin-left: 10px;
      animation: 1s linear loading-rotate infinite;
    }
  }

  &--horizontal {
    .plan__body {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      padding: 0 24px;
    }
  }

  &--loading {
    .plan__name,
    .plan__info,
    .plan__terms,
    .plan__subtitle,
    .plan__duration,
    .plan__promo,
    .plan__icon,
    .plan__hr,
    .plan__details,
    .plan__button {
      display: none;
    }

    .plan__body {
      display: block;
      height: 275px;
      background: none;
      @include gradient-animation;
      color: transparent;
      border: none;
      border-radius: 24px;
    }

    .plan__skeleton__title {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 0px;

      .title {
        height: 25px;
        width: 120px;
        @include gradient-animation;
        animation-delay: 1s;
        margin-bottom: 10px;
      }
      .subtitle {
        height: 25px;
        width: 220px;
        @include gradient-animation;
        animation-delay: 1s;
      }

      .button {
        position: absolute;
        bottom: 20px;
        height: 40px;
        width: 200px;
        @include gradient-animation;
        border-radius: 100px;
        animation-delay: 1s;
      }
    }
  }
}
</style>
