<template>
  <div class="release-date-picker">
    <div class="input-wrapper" @click.stop="openModal">
      <InputWithLabel
        :label="$t('release_date')"
        placeholder="Choose date"
        :readonly="true"
        v-model="formattedDate"
        :offer="isEarlyDate ? 'Ускоренный релиз' : ''"
      />
    </div>

    <Modal
      :visibility="isOpen"
      @switch="isOpen = false"
      :heading="'Choose a release date'"
    >
      <div class="modal-body">
        <div class="calendar-wrapper">
          <VueCal
            :events="earlyDate"
            :locale="'en'"
            :start-week="1"
            hide-view-selector
            :time="false"
            xsmall
            active-view="month"
            :disable-views="['day', 'week', 'year', 'years']"
            :min-date="minDate"
            :max-date="maxDate"
            @cell-click="onDateClick"
          />
        </div>
        <div class="dates-info">
          <div class="dates-info__wrapper">
            <div class="dates-info__row">
              <div class="date-cell">31</div>
              <div class="date-info">Дата доступная для выбора</div>
            </div>
            <div class="dates-info__row">
              <div class="date-cell date-cell--unavailable">31</div>
              <div class="date-info">Дата недоступная для выбора</div>
            </div>
            <div class="dates-info__row">
              <div class="date-cell date-cell--early">31</div>
              <div class="date-info">Дата приоритетного выпуска релиза</div>
            </div>
          </div>

          <div class="choose-date-block">
            <transition name="fade">
              <div class="early-date" v-show="isEarlyDate">
                <SvgIcon type="mdi" :path="icons.clock" />
                Услуга "Ускоренный релиз"
              </div>
            </transition>
            <transition name="fade">
              <h4 class="date" v-show="selectedDate">
                Вы выбрали дату: <span>{{ formattedDate }}</span>
              </h4>
            </transition>
            <div class="actions">
              <RedButton small v-show="selectedDate" @click="selectDate">
                Выбрать дату
              </RedButton>
              <RedButton small gray @click="closeModal">Отмена</RedButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import InputWithLabel from "@/components/InputWithLabel.vue";
import Modal from "@/components/Modal.vue";
import RedButton from "@/components/RedButton.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClockFast } from "@mdi/js";

export default {
  components: {
    VueCal,
    SvgIcon,
    InputWithLabel,
    Modal,
    RedButton,
  },
  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Object,
    },
    maxDate: {
      type: Object,
    },
  },
  data() {
    return {
      selectedDate: this.modelValue,
      isOpen: false,
      icons: {
        clock: mdiClockFast,
      },
    };
  },
  watch: {
    modelValue(newVal) {
      this.selectedDate = newVal; 
    },
    selectedDate(newVal) {
      newVal.setHours(0, 0, 0, 0);
      this.$emit("update:modelValue", newVal);
    },
  },
  computed: {
    formattedDate() {
      if (!this.selectedDate) return "";
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Date(this.selectedDate).toLocaleDateString("ru-RU", options);
    },
    isEarlyDate() {
      return (
        this.selectedDate &&
        this.selectedDate > this.minDate &&
        this.selectedDate <= this.earlyEndDate
      );
    },
    earlyStartDate() {
      return new Date(this.minDate);
    },
    earlyEndDate() {
      const end = new Date(this.earlyStartDate);
      end.setDate(this.earlyStartDate.getDate() + 4);
      return end;
    },
    earlyDate() {
      return [
        {
          start: this.earlyStartDate,
          end: this.earlyEndDate,
          title: "Special Day 1",
          color: "#FF6347",
        },
      ];
    },
  },
  methods: {
    onDateClick(date) {
      this.selectedDate = new Date(date);
    },
    selectDate() {
      this.isOpen = false;
    },
    closeModal() {
      this.isOpen = false;
    },
    openModal() {
      this.isOpen = true;
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/_shared.scss";

.modal-body {
  display: flex;

  .dates-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .dates-info__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .date-info {
        margin-left: 20px;
        max-width: 200px;
      }
    }

    .choose-date-block {
      .date {
        font-weight: 400;
        margin-bottom: 5px;

        span {
          margin-left: 5px;
          font-weight: 600;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-start;

        button:nth-child(2) {
          margin-left: 5px;
        }
      }
    }
  }
}

.date-cell {
  width: 49px;
  height: 53px;
  border: 1px solid #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--unavailable {
    background-color: #f5f5f5 !important;
    color: #ccc !important;
    cursor: not-allowed !important;
  }

  &--early {
    background-color: #ffefe6 !important;
    color: #e2732e;
    cursor: pointer;
  }
}

.early-date {
  color: #f8853e;
  margin: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  svg {
    margin-right: 5px;
  }
}

.release-date-picker {
  width: 100%;
}

.calendar-wrapper {
  width: 350px;
  height: 400px;
}

/* Событийные даты */
.vuecal__cell--has-events {
  background-color: #ffefe6 !important;
  color: #e2732e;
  cursor: pointer;

  &:hover {
    background-color: #f8853e !important;
    color: white;
  }
}

/* Количество событий скрыто */
.vuecal__cell-events-count {
  display: none !important;
}

/* Выбранная дата */
.vuecal__cell--selected {
  background-color: #8dc57c !important;
  color: white !important;
}

/* Событийная дата и выбранная одновременно */
.vuecal__cell--has-events.vuecal__cell--selected {
  background-color: #ffd700 !important;
  color: black !important;
}

/* Даты до min */
.vuecal__cell--disabled {
  background-color: #f5f5f5 !important;
  color: #ccc !important;
  cursor: not-allowed !important;

  &:hover {
    background-color: #f5f5f5 !important;
    color: #ccc !important;
  }
}

/* Для обычных доступных дат */
.vuecal__cell:not(.vuecal__cell--has-events):not(.vuecal__cell--disabled) {
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
    color: #000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
