import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

// layouts
import Layout from '../layouts/Layout.vue';
import LayoutPage from '../layouts/LayoutPage.vue';

// pages
import Main from '../views/Main.vue';
import Settings from '../views/Settings.vue';
import AllReleases from '../views/AllReleases.vue';
import Drafts from '../views/Drafts.vue';
import Statistics from '../views/Statistics.vue';
import ReleaseStatistics from '../views/ReleaseStatistics.vue';
import LayoutWelcome from '../layouts/LayoutWelcome.vue';
import SignIn from '../views/SignIn.vue';
import SignUp from '../views/SignUp.vue';
import ChangePassword from '../views/ChangePassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import VerifyEmail from '../views/VerifyEmail.vue';
import Upload from '../views/Upload.vue';
import NotFound from '../views/NotFound.vue';
import SupportDialog from '../views/SupportDialog.vue';
import Artists from '../views/Artists.vue';
import Release from '../views/ReleasePreview.vue';
import Mobile from '../views/Mobile.vue';
import Landing from '../views/Landing.vue';
import Unavailable from '../views/Unavailable.vue';
import Payments from '../views/Payments.vue';
import OrderPage from '../views/OrderPage.vue';
import Transfering from '../views/Transfering.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: LayoutPage,
        children: [
          {
            path: '/',
            name: 'Main',
            component: Main,
            meta: {
              auth: true,
            },
          },
          {
            path: '/settings',
            name: 'Settings',
            component: Settings,
            meta: {
              auth: true,
            },
          },
          {
            path: '/statistics',
            name: 'Statistics',
            component: Statistics,
            meta: {
              auth: true,
            },
          },
          {
            path: '/releases',
            name: 'All Releases',
            component: AllReleases,
            meta: {
              auth: true,
            },
          },
          {
            path: '/drafts',
            name: 'Drafts',
            component: Drafts,
            meta: {
              auth: true,
            },
          },
          // {
          //   path: '/subscriptions',
          //   name: 'Subscriptions',
          //   component: Subscriptions,
          //   meta: {
          //     auth: true,
          //   },
          // },
          // {
          //   path: '/support',
          //   name: 'Support',
          //   component: Support,
          //   meta: {
          //     auth: true,
          //   },
          // },
          {
            path: '/purchases',
            name: 'Purchases',
            component: Payments,
            meta: {
              auth: true,
            },
          },
          {
            path: '/purchases/:uuid',
            name: 'Purchase',
            component: Payments,
            meta: {
              auth: true,
            },
          },
          {
            path: '/support/:uuid',
            name: 'SupportDialog',
            component: SupportDialog,
            meta: {
              auth: true,
            },
          },
          {
            path: '/statistics/:uuid',
            name: 'Release Statistics',
            component: ReleaseStatistics,
            meta: {
              auth: true,
            },
          },
          {
            path: '/artists',
            name: 'Artists',
            component: Artists,
            meta: {
              auth: true,
            },
          },
          {
            path: '/releases/:uuid',
            name: 'Release',
            component: Release,
            meta: {
              auth: true,
            },
          },
          {
            path: '/order/:base64',
            name: 'Order',
            component: OrderPage,
            meta: {
              auth: true,
            },
          },
        ],
      },
      {
        path: '/upload',
        name: 'Upload',
        component: Upload,
        meta: {
          auth: true,
        },
      },
      {
        path: '/drafts/:uuid',
        name: 'Draft',
        component: Upload,
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: LayoutWelcome,
    children: [
      // {
      //   path: '/welcome',
      //   name: 'Welcome',
      //   component: Welcome,
      // },
      {
        path: '/transfering/:transfering_token',
        name: 'Transfering',
        component: Transfering,
      },
      {
        path: '/signup',
        name: 'Sign Up',
        component: SignUp,
      },
      {
        path: '/signin',
        name: 'Sign In',
        component: SignIn,
      },
      {
        path: '/resetpassword',
        name: 'Reset Password',
        component: ResetPassword,
      },
      {
        path: '/changepassword',
        name: 'Change Password',
        component: ChangePassword,
      },
      {
        path: '/verify/:token',
        name: 'Verify Email',
        component: VerifyEmail,
      },
    ],
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile,
    meta: {
      mobile: true,
    },
  },
  {
    path: '/landing',
    name: 'MyLabel',
    component: Landing,
  },
  {
    path: '/503',
    name: 'Service is unavailable',
    component: Unavailable,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function checkTokenRelevance (route) {
  const checkTokenPromise = new Promise((resolve, reject) => {
    if (route.meta.auth) {
      console.log('presentTime');

      let accessToken = null; let tokenPayload = null; let tokenLifeTime = null; let presentTime = null;
      accessToken = localStorage.getItem('access_token');

      if (accessToken) {
        tokenPayload = Buffer.from(accessToken.split('.')[1], 'base64');
        tokenLifeTime = JSON.parse(tokenPayload).exp;
        presentTime = parseInt((new Date().getTime()) / 1000);
      }

      if (!accessToken || (presentTime > tokenLifeTime)) {
        console.log('router:: обновление токенов');

        if (!store.getters.pendingUpdate_RefreshTokens) {
          store.dispatch('refreshTokens').then(() => {
            console.log('router guard:: success');
            resolve(true);
          }).catch(() => {
            router.push({ name: 'Sign In' });
          });
        } else {
          store.getters.pendingUpdate_RefreshTokens.then(() => {
            resolve(true);
          }).catch(() => {
            router.push({ name: 'Sign In' });
          });
        }
      } else {
        resolve(true);
      }
    } else {
      resolve(true);
    }
  });
  return checkTokenPromise;
}

function getUserMobileBool () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(a.substr(0, 4))
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

function routeMobileVersionCheck (route) {
  const isUserMobile = getUserMobileBool();

  if (route.name == 'Mobile' && !isUserMobile) {
    router.push({ name: 'Main' });
  }

  const isRouteHasMobileVersion = route.meta.mobile;
  if (!isRouteHasMobileVersion && isUserMobile) {
    router.push({ name: 'Mobile' });
  }
}

router.beforeEach((to) => {
  // routeMobileVersionCheck(to);
  return true;
});

router.afterEach((to) => {
  store.dispatch('startAppLoading');
  checkTokenRelevance(to).finally(() => {
    store.dispatch('finishAppLoading');
    if (!store.getters.isAppDidRouterNavigations) {
      store.dispatch('commitRouterNavigation');
    }
  });
  return true;
});

export default router;
